import type React from "react"

import { Box } from "@chakra-ui/react"

const InputLabel: React.FC<{ text: string }> = ({ text }) => (
  <Box
    as="span"
    fontFamily={[
      "Noto Sans JP",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
    ].join(",")}
    fontSize="1em"
    fontWeight="bold"
  >
    {text}
  </Box>
)

// biome-ignore lint/style/noDefaultExport: ...
export default InputLabel
