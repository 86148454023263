import { appHost } from "./config"

import type { AwsConfig } from "common-utils"

const awsConfig: AwsConfig = {
  Auth: {
    region: "ap-northeast-1",
    userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_COGNITO_APP_CLIENT_ID,
    identityPoolId: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID,
    // TODO: α版ユーザーの移行が完了したら、下記の設定を削除してデフォルトのUSER_SRP_AUTHに戻す
    // https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-import-using-lambda.html
    authenticationFlowType: "USER_PASSWORD_AUTH",
    oauth: {
      domain: import.meta.env.VITE_COGNITO_OAUTH_DOMAIN,
      scope: ["openid", "email", "profile"],
      redirectSignIn: `${appHost}/profile/new`,
      redirectSignOut: appHost,
      responseType: "code",
      options: {
        AdvancedSecurityDataCollectionFlag: false,
      },
    },
  },
  Storage: {
    AWSS3: {
      bucket: import.meta.env.VITE_S3_STORAGE_BUCKET,
      region: "ap-northeast-1",
    },
  },
}

// biome-ignore lint/style/noDefaultExport: ...
export default awsConfig
