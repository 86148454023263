import { Button } from "@chakra-ui/react"

import { LineIcon } from "../atoms"

import type { ButtonProps } from "@chakra-ui/react"

const LineButton = (props: ButtonProps) => (
  <Button
    leftIcon={<LineIcon boxSize="10" />}
    bg="#06C755"
    _hover={{ opacity: "0.8" }}
    textColor="white"
    {...props}
  />
)

// biome-ignore lint/style/noDefaultExport: ...
export default LineButton
