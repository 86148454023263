import { useEffect, useState } from "react"

import { Auth } from "aws-amplify"

const useCognitoToken = () => {
  const [jwt, setJwt] = useState<string>()

  useEffect(() => {
    const getJwt = async () => {
      const session = await Auth.currentSession()
      setJwt(session.getAccessToken().getJwtToken())
    }
    void getJwt()
  })

  return { jwt }
}

// biome-ignore lint/style/noDefaultExport: ...
export default useCognitoToken
