import { useParams } from "react-router-dom"

import { Avatar, Box, Flex, Heading, Stack, Text } from "@chakra-ui/react"

import { useJobSeekerShowMemberQuery } from "~/generated/graphql"

const MemberDetail = () => {
  const { id = "" } = useParams<{ id: string }>()

  const { loading, data } = useJobSeekerShowMemberQuery({
    variables: { id },
    skip: !id,
  })
  const member = data?.member

  if (loading || !member) {
    return <div>loading...</div>
  }

  return (
    <Box sx={{ maxW: 800, my: 50, mx: "auto", px: 4 }}>
      <Flex sx={{ mb: 10 }}>
        <Avatar src={member.avatar || ""} size={{ base: "xl", md: "2xl" }} />
        <Stack spacing={1} sx={{ ml: { base: 5, md: 10 }, justifyContent: "center" }}>
          <Heading size="md">{member.name}</Heading>
          <Text>{member.company.name}</Text>
          <Text>{member.jobTitle}</Text>
        </Stack>
      </Flex>
      <Stack spacing={12}>
        {member.bio && (
          <Box>
            <Heading size="md">自己紹介</Heading>
            <Text sx={{ whiteSpace: "pre-wrap", py: 2 }}>{member.bio}</Text>
          </Box>
        )}
        {member.messageToStudents && (
          <Box>
            <Heading size="md">学生の皆様へ！先輩からのメッセージ</Heading>
            <Text sx={{ whiteSpace: "pre-wrap", py: 2 }}>{member.messageToStudents}</Text>
          </Box>
        )}
      </Stack>
    </Box>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default MemberDetail
