import type React from "react"
import type { SingleValue } from "react-select"

import { Skeleton } from "@chakra-ui/react"
import CreatableSelect from "react-select/creatable"

import { useJobSeekerMeQuery } from "~/generated/graphql"

import educationalInstitutionsJson from "./educationInstitutions.json"

import type { EducationalInstitution } from "rikeimatch-graphql"

const EducationalInstitutionSelect: React.FC<{
  onChange: (value: string) => void
}> = ({ onChange }) => {
  const { data, loading } = useJobSeekerMeQuery()
  // const {
  //   data: educationalInstitutionsData,
  //   loading: educationalInstitutionsLoading,
  //   error,
  //   refetch,
  // } = useEducationalInstitutionsQuery();
  const defaultValue = () => {
    if (!loading) {
      const eduName = data?.jobSeekerMe?.educationHistory?.name

      return { label: eduName, value: eduName }
    }

    return undefined
  }
  const options = () =>
    (educationalInstitutionsJson as [EducationalInstitution]).map(
      (edu: EducationalInstitution) => ({
        label: edu.name,
        value: edu.name,
      }),
    )
  // if (!educationalInstitutionsLoading) {
  //   return (educationalInstitutionsData?.educationalInstitutions || []).map(
  //     (edu: EducationalInstitution) => ({ label: edu.name, value: edu.name }),
  //   );
  // }
  // // 新規登録で同時に複数のリクエストが発火され、サーバで同時にユーザを永続化しようとして失敗する
  // // TODO: ここでリトライしているが本当は少し待ってからリクエストを送りたい
  // if (error) {
  //   setTimeout(() => {
  //     void refetch();
  //   }, 1000);
  // }

  // return [];
  const handleChange = (
    newValue: SingleValue<{
      label: string | undefined
      value: string | undefined
    }>,
  ) => {
    if (newValue?.label && newValue?.value) {
      onChange(newValue?.label)
    }
  }

  return loading ? (
    <Skeleton height="20px" />
  ) : (
    <CreatableSelect
      onChange={handleChange}
      defaultValue={defaultValue()}
      formatCreateLabel={(newLabel: string) => newLabel}
      options={options()}
      noOptionsMessage={() => "選択肢がありません"}
    />
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default EducationalInstitutionSelect
