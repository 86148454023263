import type { ReactNode } from "react"

import { FormControl as ChakraFormControl } from "@chakra-ui/react"

import type { FormControlProps } from "@chakra-ui/react"

type Props = {
  children: ReactNode
} & FormControlProps

const FormControl = ({ children, ...props }: Props): ReactNode => (
  <ChakraFormControl mb={5} {...props}>
    {children}
  </ChakraFormControl>
)

// biome-ignore lint/style/noDefaultExport: ...
export default FormControl
