import type React from "react"

import CampaignModal from "~/components/organisms/CampaignModal"
import { useJobSeekerLatestCampaignsQuery, useJobSeekerMeQuery } from "~/generated/graphql"

const CampaignProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: campaignsData, loading } = useJobSeekerLatestCampaignsQuery()
  const { data: jobSeekerData } = useJobSeekerMeQuery()
  const jobSeeker = jobSeekerData?.jobSeekerMe
  const doneSetup = !!jobSeeker?.educationHistory
  const campaigns = campaignsData?.latestCampaigns

  return (
    <>
      {!loading && doneSetup && campaigns && <CampaignModal campaigns={campaigns} />}
      {children}
    </>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default CampaignProvider
