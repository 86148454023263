import type { EducationHistoryGrade } from "rikeimatch-graphql"

export const grades: { [key in EducationHistoryGrade]: string } = {
  b1: "学部1年",
  b2: "学部2年",
  b3: "学部3年",
  b4: "学部4年",
  b5: "学部5年",
  b6: "学部6年",
  m1: "修士1年",
  m2: "修士2年",
  d1: "博士1年",
  d2: "博士2年",
  d3: "博士3年",
  d4: "博士4年",
  s1: "高専1年",
  s2: "高専2年",
  s3: "高専3年",
  s4: "高専4年",
  s5: "高専5年",
  c1: "専門1年",
  c2: "専門2年",
  c3: "専門3年",
  c4: "専門4年",
  graduated: "既卒",
} as const

export const prefectures = {
  hokkaido: "北海道",
  aomori: "青森県",
  iwate: "岩手県",
  miyagi: "宮城県",
  akita: "秋田県",
  yamagata: "山形県",
  fukushima: "福島県",
  ibaraki: "茨城県",
  tochigi: "栃木県",
  gunma: "群馬県",
  saitama: "埼玉県",
  chiba: "千葉県",
  tokyo: "東京都",
  kanagawa: "神奈川県",
  niigata: "新潟県",
  yamanashi: "山梨県",
  nagano: "長野県",
  toyama: "富山県",
  ishikawa: "石川県",
  fukui: "福井県",
  gifu: "岐阜県",
  shizuoka: "静岡県",
  aichi: "愛知県",
  mie: "三重県",
  shiga: "滋賀県",
  kyoto: "京都府",
  osaka: "大阪府",
  hyogo: "兵庫県",
  nara: "奈良県",
  wakayama: "和歌山県",
  tottori: "鳥取県",
  shimane: "島根県",
  okayama: "岡山県",
  hiroshima: "広島県",
  yamaguchi: "山口県",
  tokushima: "徳島県",
  kagawa: "香川県",
  ehime: "愛媛県",
  kochi: "高知県",
  fukuoka: "福岡県",
  saga: "佐賀県",
  nagasaki: "長崎県",
  kumamoto: "熊本県",
  oita: "大分県",
  miyazaki: "宮崎県",
  kagoshima: "鹿児島県",
  okinawa: "沖縄県",
  overseas: "海外",
} as const

export const companyLocations = {
  ...prefectures,
  all_over_japan: "全国",
} as const
export const jobLocations = companyLocations

export const prefecturesForProfile = prefectures

export const prefecturesForDesiredWork = {
  ...{ all_over_japan: "全国可" },
  ...prefectures,
} as const

export const regions = {
  hokkaido: { name: "北海道", prefectures: ["hokkaido"] },
  tohoku: {
    name: "東北",
    prefectures: ["aomori", "iwate", "miyagi", "akita", "yamagata", "fukushima"],
  },
  kanto: {
    name: "関東",
    prefectures: ["ibaraki", "tochigi", "gunma", "saitama", "chiba", "tokyo", "kanagawa"],
  },
  chubu: {
    name: "中部",
    prefectures: ["niigata", "yamanashi", "nagano", "toyama", "ishikawa", "fukui", "shizuoka"],
  },
  tokai: { name: "東海", prefectures: ["aichi", "mie", "gifu"] },
  kinki: { name: "近畿", prefectures: ["shiga", "kyoto", "osaka", "hyogo", "nara", "wakayama"] },
  chugoku_shikoku: {
    name: "中国四国",
    prefectures: [
      "tottori",
      "shimane",
      "okayama",
      "hiroshima",
      "yamaguchi",
      "tokushima",
      "kagawa",
      "ehime",
      "kochi",
    ],
  },
  kyushu: {
    name: "九州",
    prefectures: [
      "fukuoka",
      "saga",
      "nagasaki",
      "kumamoto",
      "oita",
      "miyazaki",
      "kagoshima",
      "okinawa",
    ],
  },
} as const

export const desiredCompanySizes = {
  enterprise: "大手企業",
  mid_size: "中小企業",
  mega_venture: "メガベンチャー",
  venture: "ベンチャー",
}

export const scoutStatuses = {
  name: "貴社からのアプローチ(スカウト)",
  fields: {
    not_scouted: "スカウト未送信",
    scouted: "スカウト送信済み",
  },
} as const

export const jobLikeStatuses = {
  name: "貴社からのアプローチ(興味あり)",
  fields: {
    not_received_joblike: "興味あり未送信",
    received_joblike: "興味あり送信済み",
  },
} as const

export const profileStatus = {
  collecting_information: "情報収集",
  looking_for_internship: "インターン検討中",
  looking_for_job: "就活中（内定無し）",
  has_offer_and_looking_for_job: "就活中（内定有りだが、まだ探したい）",
  accepted_offer: "就活終了（内定承諾済）",
} as const

export const jobApplicationStatusKinds = {
  application_received: "応募受付",
  casual_interview: "カジュアル面談",
  information_session: "説明会",
  internship: "インターン",
  written_test: "筆記試験",
  document_screening: "書類選考",
  first_interview: "1次面接",
  second_interview: "2次面接",
  third_interview: "3次面接",
  final_interview: "最終面接",
  job_offer: "内定",
  job_offer_accepted: "内定承諾",
  long_term_internship_accepted: "長期インターン稼働決定",
  selection_withdrawal: "選考辞退",
  not_hired: "不採用",
  non_selection_target: "非選考対象",
} as const

export const desiredJobTypeOptions = [
  "設計・開発（機械）",
  "設計・開発（電気電子）",
  "研究開発",
  "生産技術",
  "生産管理",
  "品質管理",
  "技術営業",
  "システムエンジニア（SE）",
  "WEBエンジニア",
  "モバイルエンジニア",
  "AIエンジニア",
  "組み込み開発エンジニア",
  "インフラエンジニア",
  "プリセールス",
  "ゲーム開発",
  "データサイエンティスト",
  "ITコンサルタント",
  "戦略コンサルタント",
  "建築設計",
  "施工管理",
  "金融専門職",
  "営業",
  "マーケティング",
  "人事",
  "経理",
].map((jobType) => ({ id: jobType, name: jobType }))

export const jobTargetGrades = {
  graduation_2024: "24卒",
  graduation_2025: "25卒",
  graduation_2026: "26卒",
  graduation_2027: "27卒",
  graduation_2028: "28卒",
  all: "学年不問",
} as const

// biome-ignore lint/style/noDefaultExport: ...
export default {
  resources: {
    latestStatuss: {
      name: "選考状況",
      fields: {
        kind: "選考状況",
      },
    },
    jobs: {
      name: "求人情報",
      fields: {
        title: "求人タイトル",
        description: "仕事内容",
        status: "ステータス",
        coverImage: "メイン画像（1280x720）",
        images: "画像(400×300)",
        image1: "画像1(400×300)",
        image2: "画像2(400×300)",
        image3: "画像3(400×300)",
        members: "一緒に働くメンバー",
        memberName: "名前",
        memberImage: "画像",
        idealCandidate: "求める人物",
        isFeatureable: "掲載許可",
        characteristicLabels: "求人の特徴ラベル",
        kind: "求人の種類",
        locations: "勤務地",
        productInfo: "製品情報",
        productImages: "製品画像",
        paragraphs: "自由項目（3つまで）",
        targetGrades: "対象学年",
      },
      statuses: {
        draft: "下書き",
        submitted: "審査中",
        published: "公開中",
        unpublished: "非公開",
      },
      kinds: {
        full_time: "本選考",
        internship: "インターン",
      },
      targetGrades: jobTargetGrades,
    },
    jobParagraphs: {
      name: "自由項目",
      fields: {
        title: "タイトル",
        body: "内容",
      },
    },
    jobSeekers: {
      actions: {
        search: { name: "候補者検索" },
        freeSearch: "自由検索",
        scout: "スカウトを送る",
        like: "興味ありを送る",
        achievement: "制作物の詳細",
        interviewHistory: "AI面接診断における質疑応答",
      },
      name: "候補者",
      fields: {
        createdAt: "登録日",
        image: "画像",
        email: "メールアドレス",
        address: "現住所",
        placeOfBirth: "出身地",
        status: "内定状況",
        fullName: "氏名",
        desiredJobTypes: "希望職種",
        desiredWorkLocations: "希望勤務地",
        desiredCompanySizes: "希望企業規模",
        studyKeywords: "学習（タグ）",
        studyDetail: "学習詳細",
        certifications: "保有資格",
        toeicScore: "TOEICスコア",
        focusSummary: "学生時代に力を入れたこと（概要）",
        thingsChangedAfterCovid: "コロナ禍で変化した意識や行動",
        focusDetail: "学生時代に力を入れたこと（詳細）",
        socialAccounts: "ソーシャルアカウント",
        achievements: "制作物",
        bio: "自己紹介",
        careerInterests: "興味関心のあること",
        careerInterestsMotivation: "価値観を選んだ動機やエピソード",
        interviewHistories: "AI面接診断の履歴",
      },
      statuses: profileStatus,
      desiredCompanySizes,
      scoutStatuses,
      jobLikeStatuses,
    },
    jobSeekerLikes: {
      name: "興味あり",
      menu: { name: "興味あり" },
      fields: {
        status: "応募状況",
        sentAt: "送信日",
        receivedAt: "受信日",
        interestLevel: "関心度",
      },
      statuses: {
        liked: "興味あり",
        accepted: "応募済み",
        waiting: "未返信",
      },
      directions: {
        seeker_to_company: "学生→貴社",
        company_to_seeker: "貴社→学生",
      },
      interestLevel: {
        high: "興味あり送信時に「応募したい」と回答した関心度の高い学生です。",
        middle: "興味あり送信時に「まずは話を聞いてみたい」と回答した関心度のやや高い学生です。",
        low: "興味あり送信時に「調べ中」と回答した関心度の低い学生です。",
      },
    },
    jobSeekerPrivacySettings: {
      name: "リケリンク設定",
      profileVisibilities: {
        hidden: "非公開",
        limited: "限定公開",
        visible: "公開",
      },
      profileVisibilityDescriptions: {
        hidden: "プロフィールは非公開になり、他のユーザーからは一切見ることができません。",
        limited:
          "プロフィールはURLを知っている人のみが閲覧することができ、検索エンジンには表示されません。",
        visible: "プロフィールはWeb上に公開され、検索エンジンに表示されます。",
      },
    },
    messageThreads: {
      name: "メッセージ",
      menu: { name: "スカウト済み" },
      fields: {
        createdAt: "スカウト送信日",
        scoutMailOpenedAt: "最終開封日",
        hasUnreadByJobSeeker: "学生の開封状態",
        reactionStatus: "対応状況",
      },
      origins: {
        like_from_seeker: "興味あり（学生→貴社）",
        like_from_company: "興味あり（貴社→学生）",
        scout: "スカウト",
        job_interview: "AI面接",
      },
      statuses: {
        read: "開封済",
        unread: "未開封",
        sent: "送信済",
        unanswered: "未返信",
      },
      readBy: {
        jobSeeker: "学生",
        company: "貴社",
      },
    },
    messages: {
      name: "メッセージ",
      fields: {
        createdAt: "送信日時",
        text: "本文",
      },
    },
    applicationManagement: {
      name: "応募管理",
      fields: {
        jobTitle: "求人情報",
        message: "メッセージ",
        createdAt: "応募日",
        origin: "経路",
        note: "メモ",
        latestStatus: "選考状況",
      },
      origins: {
        like_from_seeker: "興味あり（学生→貴社）",
        like_from_company: "興味あり（貴社→学生）",
        scout: "スカウト",
        job_interview: "AI面接",
      },
    },
    companies: {
      name: "企業",
      fields: {
        name: "会社名",
        email: "メールアドレス",
        representative: "代表名",
        foundedOn: "設立",
        employeesNumber: "社員数",
        logo: "ロゴ",
        coverImage: "ヘッダー画像（1280x720）",
        homepage: "会社URL",
        industry: "業種",
        prefecture: "所在地",
        headquartersAddress: "本社所在地",
        offices: "事業所",
        businessDescription: "事業内容",
        corporatePhilosophy: "企業理念",
        workStyleAttraction: "働き方の魅力",
        scoutCount: "スカウト数",
        likeCount: "興味あり数",
        expiresOn: "有効期限",
        sentScoutCount: "スカウト送信数",
        sentJobLikeCount: "興味あり送信数",
        applicationCount: "応募数",
        locations: "勤務地",
        businessCategories: "業態",
      },
      locations: companyLocations,
    },
    educationHistory: {
      name: "学歴",
      fields: {
        name: "学校名",
        grade: "学年",
        faculty: "学部",
        department: "学科",
        major: "専攻分野",
        researchSubject: "研究テーマ",
        researchDetail: "研究内容",
        enrolledOn: "入学年月日",
        graduatedOn: "卒業（見込み）",
        highSchool: "卒業高校",
      },
      grades,
    },
    prefecturesForProfile,
    prefecturesForDesiredWork,
    messageTemplates: {
      name: "メッセージテンプレート",
      fields: {
        name: "テンプレート名",
        kind: "テンプレート区分",
        text: "本文",
        updatedAt: "更新日",
      },
      kinds: {
        scout: "スカウトメッセージ",
        selection: "選考用メッセージ",
        like: "興味あり後のやり取り",
        remind: "リマインドメッセージ",
      },
      actions: {
        select: "テンプレートを選択",
      },
    },
    recruiterNotes: {
      fields: {
        comment: "コメント",
      },
    },
    jobSeekerSearchConditions: {
      name: "検索条件テンプレート",
      fields: {
        name: "テンプレート名",
      },
    },
    members: {
      name: "メンバー",
      fields: {
        name: "名前",
        jobTitle: "部署・役職",
        bio: "紹介文",
        avatar: "写真",
        messageToStudents: "学生へのメッセージ",
      },
    },
    products: {
      name: "自社製品",
      fields: {
        name: "製品名",
        description: "製品紹介",
        image: "製品イメージ",
      },
    },
    featureTopics: {
      name: "特集ページ",
      fields: {
        slug: "名前",
        title: "タイトル",
        description: "説明",
      },
    },
    featuredJobs: {
      name: "特集求人",
    },
    jobCharacteristics: {
      name: "求人の特徴",
      fields: {
        name: "特徴",
        jobCharacteristicLabelsCount: "ラベル使用数",
      },
    },
    mailNotifications: {
      name: "メール通知",
      fields: {
        subject: "件名",
        body: "本文",
        sendAt: "配信予定日時",
      },
    },
    jobApplicationStatuses: {
      kinds: jobApplicationStatusKinds,
    },
    chatGptMessages: {
      directions: {
        assistant: "AI面接官",
        user: "学生",
      },
    },
    jobInterviews: {
      name: "AI面接",
      fields: {
        createdAt: "作成日",
        jobSeeker: "学生",
      },
    },
  },
} as const
