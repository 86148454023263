import type React from "react"
import type { MultiValue } from "react-select"

import { Skeleton } from "@chakra-ui/react"
import CreatableSelect from "react-select/creatable"

import { useJobSeekerMeQuery } from "~/generated/graphql"

type Option = { label: string; value: string }
const OPTIONS = [
  { value: "熱力学", label: "熱力学" },
  { value: "材料力学", label: "材料力学" },
  { value: "流体力学", label: "流体力学" },
  { value: "機械力学", label: "機械力学" },
  { value: "機械設計", label: "機械設計" },
  { value: "エンジン", label: "エンジン" },
  { value: "ロボット工学", label: "ロボット工学" },
  { value: "工作機械", label: "工作機械" },
  { value: "旋盤", label: "旋盤" },
  { value: "CAD", label: "CAD" },
  { value: "3DCAD", label: "3DCAD" },
  { value: "AutoCAD", label: "AutoCAD" },
  { value: "SolidWorks", label: "SolidWorks" },
  { value: "CATIA", label: "CATIA" },
  { value: "マイクロメータ", label: "マイクロメータ" },
  { value: "電気回路", label: "電気回路" },
  { value: "電子回路", label: "電子回路" },
  { value: "パワーエレクトロニクス", label: "パワーエレクトロニクス" },
  { value: "制御工学", label: "制御工学" },
  { value: "半導体工学", label: "半導体工学" },
  { value: "回路設計", label: "回路設計" },
  { value: "LSI設計", label: "LSI設計" },
  { value: "組み込みシステム", label: "組み込みシステム" },
  { value: "CAD", label: "CAD" },
  { value: "AutoCAD", label: "AutoCAD" },
  { value: "OrCAD", label: "OrCAD" },
  { value: "オシロスコープ", label: "オシロスコープ" },
  { value: "プログラミング", label: "プログラミング" },
  { value: "画像処理", label: "画像処理" },
  { value: "人工知能", label: "人工知能" },
  { value: "解析学", label: "解析学" },
  { value: "オブジェクト指向", label: "オブジェクト指向" },
  { value: "組み込みシステム", label: "組み込みシステム" },
  { value: "C言語", label: "C言語" },
  { value: "Java", label: "Java" },
  { value: "Ruby", label: "Ruby" },
  { value: "Python", label: "Python" },
  { value: "JavaScript", label: "JavaScript" },
  { value: "HTML", label: "HTML" },
  { value: "React", label: "React" },
  { value: "Laravel", label: "Laravel" },
  { value: "AWS", label: "AWS" },
  { value: "Linux", label: "Linux" },
  { value: "MySQL", label: "MySQL" },
]

const StudyKeywordsSelect: React.FC<{
  onChange: (value: string[]) => void
}> = ({ onChange }) => {
  const { data, loading } = useJobSeekerMeQuery()
  const defaultValue = () => {
    if (!loading) {
      return (data?.jobSeekerMe?.studyKeywords || []).map((label) => ({
        label,
        value: label,
      }))
    }

    return []
  }
  const handleChange = (newValue: MultiValue<Option>) => {
    const studyKeywords = newValue.map((detail) => detail.label)
    onChange(studyKeywords)
  }

  return loading ? (
    <Skeleton height="20px" />
  ) : (
    <CreatableSelect
      isClearable
      isMulti
      onChange={handleChange}
      placeholder="例: 機械設計 機械力学 電気回路 半導体 2DCAD Solidworks C言語 Java"
      defaultValue={defaultValue()}
      formatCreateLabel={(newLabel: string) => `${newLabel}を追加する`}
      noOptionsMessage={() => "選択肢がありません"}
      options={OPTIONS}
      closeMenuOnSelect={false}
    />
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default StudyKeywordsSelect
