import type React from "react"
import { useForm } from "react-hook-form"
import type { SubmitHandler } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"

import { Box, Button, Flex, Input, Text } from "@chakra-ui/react"

import { InputLabel } from "~/components/atoms"
import SectionHeader from "~/components/molecules/SectionHeader"
import { useUser } from "~/containers"

import LineLogin from "../molecules/LineLogin"

import styles from "./AuthForm.module.css"

type SignInFormInput = {
  email: string
  password: string
}

export type Props = {
  afterComplete: () => void
  showSignUpForm: () => void
  showResetPasswordForm: () => void
}

const SignInForm: React.FC<Props> = ({ showSignUpForm, showResetPasswordForm, afterComplete }) => {
  const navigate = useNavigate()
  const { login } = useUser()

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<SignInFormInput>({
    mode: "onBlur",
  })

  const onSubmit: SubmitHandler<SignInFormInput> = async (input) => {
    try {
      await login(input.email, input.password)
      afterComplete()
      navigate("/topics")
    } catch {
      toast.error("ログインに失敗しました")
    }
  }

  return (
    <>
      <SectionHeader title="ログイン" subtitle="login" />
      <Box maxW="500px" margin="auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box py="15px">
            <InputLabel text="メールアドレス" />
            <Input
              autoComplete="username"
              className={styles.txt}
              type="email"
              required
              isInvalid={!!errors.email}
              {...register("email", { required: true })}
            />
            <Text color="#b8526b">
              {errors.email?.type !== "required" && errors.email?.message}
            </Text>
          </Box>
          <Box py="15px">
            <InputLabel text="パスワード" />
            <Input
              autoComplete="current-password"
              className={styles.txt}
              type="password"
              minLength={8}
              required
              isInvalid={!!errors.password}
              {...register("password", { required: true })}
            />
            <Text fontSize="md">
              <Button variant="link" textColor="#0a94cf" onClick={showResetPasswordForm}>
                パスワードを忘れた方はこちら
              </Button>
            </Text>
          </Box>
          <Flex alignItems="center" direction="column" py="30px">
            <Button
              type="submit"
              isLoading={isSubmitting}
              w="240px"
              h="50px"
              bg="#0a94cf"
              color="white"
              fontWeight="normal"
              fontSize="1.5rem"
              // borderRadius="0"
              padding="0.6em 0"
              marginBottom="20px"
              _hover={{ bg: "#cf1359" }}
            >
              ログイン
            </Button>
            <Text
              w="100%"
              textAlign="center"
              borderBottom="2px solid gray"
              lineHeight="0.1em"
              margin="10px 0 20px"
            >
              <Text as="span" fontSize="sm" fontWeight="normal" padding="0 10px" bg="white">
                または
              </Text>
            </Text>
            <LineLogin />
            <Text py="20px">
              <Button variant="link" textColor="#0a94cf" onClick={showSignUpForm}>
                新規会員登録はこちら
              </Button>
            </Text>
          </Flex>
        </form>
      </Box>
    </>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default SignInForm
