import type React from "react"
import { useForm } from "react-hook-form"
import type { SubmitHandler } from "react-hook-form"

import { Box, FormHelperText, FormLabel, HStack, Input, Select, VStack } from "@chakra-ui/react"
import { ISODateTimeToDateString, i18n, objectToOptions } from "common-utils"

import { notifySaveFailure, notifySaveSuccess } from "~/components/atoms"
import { FormControl, InputContainer } from "~/components/molecules"
import { useJobSeekerMeQuery, useJobSeekerUpdateProfileMutation } from "~/generated/graphql"

import type { JobSeekerStatus } from "rikeimatch-graphql"

const prefectureArray = objectToOptions(i18n.prefecturesForProfile)
const profileStatusSelect = objectToOptions(i18n.profileStatus)

type ProfileFormInput = {
  birthday: string
  firstName: string
  lastName: string
  address: keyof typeof i18n.prefecturesForProfile
  status: JobSeekerStatus
}

const ProfileForm: React.FC = () => {
  const [updateSeekerProfile, { error }] = useJobSeekerUpdateProfileMutation()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ProfileFormInput>({
    mode: "onBlur",
    shouldFocusError: false,
  })

  useJobSeekerMeQuery({
    onCompleted: (data) => {
      const birthday = ISODateTimeToDateString(data.jobSeekerMe?.birthday)
      const defaultValues = Object.fromEntries(
        Object.entries(data.jobSeekerMe || {}).filter(([key]) =>
          ["firstName", "lastName", "address", "status"].includes(key),
        ),
      )
      reset({
        ...defaultValues,
        birthday,
      })
    },
  })

  const onSubmit: SubmitHandler<ProfileFormInput> = async (input) => {
    const birthday = ISODateTimeToDateString(input.birthday)
    await updateSeekerProfile({
      variables: {
        input: { ...input, birthday },
      },
    })
    if (error) {
      notifySaveFailure()
    } else {
      notifySaveSuccess()
    }
  }

  return (
    <form onBlur={handleSubmit(onSubmit)}>
      <Box maxW="600px" w="full">
        <FormControl paddingBottom="10px" isInvalid={!!(errors.firstName && errors.lastName)}>
          <FormLabel htmlFor="name" fontSize="sm" fontWeight="md">
            氏名
          </FormLabel>
          <HStack display={{ base: "inherit", md: "flex" }} spacing={{ base: 0, md: 2 }}>
            <VStack alignItems="flex-start">
              <Input
                w="200px"
                isRequired
                isInvalid={!!errors.lastName}
                {...register("lastName", { required: true })}
              />
              <FormHelperText>姓</FormHelperText>
            </VStack>
            <VStack alignItems="flex-start">
              <Input
                w="200px"
                isRequired
                isInvalid={!!errors.firstName}
                {...register("firstName", { required: true })}
              />
              <FormHelperText>名</FormHelperText>
            </VStack>
          </HStack>
        </FormControl>

        <InputContainer name="birthday" label="生年月日" invalid={!!errors.birthday}>
          <Input
            w="200px"
            type="date"
            isRequired
            isInvalid={!!errors.birthday}
            {...register("birthday", { required: true })}
          />
        </InputContainer>

        <InputContainer name="address" label="現住所" invalid={!!errors.address}>
          <Select
            placeholder="-"
            maxW="300px"
            isRequired
            isInvalid={!!errors.address}
            {...register("address", { required: true })}
          >
            {prefectureArray.map((prefecture) => (
              <option key={prefecture.id} value={prefecture.id}>
                {prefecture.name}
              </option>
            ))}
          </Select>
        </InputContainer>

        <InputContainer name="status" label="就活状況" invalid={!!errors.status}>
          <Select
            placeholder="-"
            maxW="300px"
            isRequired
            isInvalid={!!errors.status}
            {...register("status", { required: true })}
          >
            {profileStatusSelect.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </Select>
        </InputContainer>
      </Box>
    </form>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default ProfileForm
