import type React from "react"
import { useEffect, useState } from "react"
import "regenerator-runtime"
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition"

import { Button, Input, Spinner, Stack, Text } from "@chakra-ui/react"
import { FaMicrophone } from "react-icons/fa"
import { ChatGptMessageInputSource } from "rikeimatch-graphql"

type PropsType = {
  handleSubmit: (input: string, inputSource: ChatGptMessageInputSource) => void
  handleRetry: () => void
  hasFinished?: boolean
  loading?: boolean
}

const InterviewInput = ({ handleSubmit, handleRetry, hasFinished, loading }: PropsType) => {
  const [input, setInput] = useState<string>("")
  const [isRetrying, setIsRetrying] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const { transcript, listening, resetTranscript } = useSpeechRecognition()

  // error が出るのでコメントアウト
  /* if (!browserSupportsSpeechRecognition) {
    return <div>ブラウザが音声認識未対応です</div>;
  } */

  const handleSendTranscription = async () => {
    await SpeechRecognition.stopListening()
    if (isEditing) {
      handleSubmit(input, ChatGptMessageInputSource.Voice)
      setInput("")
      setIsEditing(false)
    } else {
      handleSubmit(transcript, ChatGptMessageInputSource.Voice)
    }
    resetTranscript()
  }

  const handleCancelTranscription = async () => {
    await SpeechRecognition.stopListening()
    resetTranscript()
  }

  const handleSendInput = () => {
    handleSubmit(input, ChatGptMessageInputSource.Text)
    setInput("")
  }

  const handleExecuteRetry = () => {
    setIsRetrying(true)
    handleRetry()
  }

  const handleEditTranscription = () => {
    setInput(transcript)
    setIsEditing(true)
  }

  useEffect(() => {
    if (isRetrying && !loading) {
      setIsRetrying(false)
    }
  }, [loading, isRetrying])

  return (
    <Stack sx={{}}>
      {!listening && (
        <Stack>
          {!hasFinished && (
            <Input
              value={input}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
            />
          )}
          {!loading && !hasFinished && (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              spacing={2}
            >
              <>
                <Button
                  leftIcon={<FaMicrophone />}
                  colorScheme="blue"
                  variant="solid"
                  onClick={() =>
                    SpeechRecognition.startListening({
                      continuous: true,
                      language: "ja-JP",
                    })
                  }
                >
                  音声入力
                </Button>
                <Button onClick={handleSendInput} colorScheme="blue">
                  送信
                </Button>
              </>
            </Stack>
          )}
          {loading && (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                py: 1,
              }}
            >
              <Spinner />
              <Text sx={{ fontSize: 12, mx: 2 }}>
                回答が返ってこない場合は、再実行ボタンを押してください。
              </Text>
              <Button onClick={handleExecuteRetry} isDisabled={isRetrying}>
                再実行
              </Button>
            </Stack>
          )}
        </Stack>
      )}
      <Stack sx={{ w: "100%", display: "flex" }}>
        {listening && isEditing && (
          <Input
            value={input}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
          />
        )}
        {listening && !isEditing && <Text sx={{ p: 4 }}>{transcript}</Text>}
        <Stack sx={{ display: "flex", alignItems: "flex-end" }}>
          {listening && (
            <Stack direction="row">
              <Button
                onClick={handleSendTranscription}
                colorScheme="blue"
                isDisabled={transcript.length === 0}
              >
                送信
              </Button>
              {!isEditing && <Button onClick={handleEditTranscription}>編集</Button>}
              <Button onClick={handleCancelTranscription}>キャンセル</Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default InterviewInput
