import type React from "react"
import { useState } from "react"

import { AuthState } from "common-utils"

import ForgotPasswordForm from "./ForgotPasswordForm"
import SignInForm from "./SignInForm"
import SignUpForm from "./SignUpForm"

type Props = {
  afterComplete: () => void
  initialState:
    | AuthState.SignIn
    | AuthState.SignUp
    | AuthState.ForgotPassword
    | AuthState.ConfirmSignIn
}

const AuthForm: React.FC<Props> = ({ initialState, afterComplete }) => {
  const [authState, setAuthState] = useState(initialState)

  switch (authState) {
    case AuthState.SignIn:
      return (
        <SignInForm
          showSignUpForm={() => setAuthState(AuthState.SignUp)}
          showResetPasswordForm={() => setAuthState(AuthState.ForgotPassword)}
          afterComplete={() => afterComplete()}
        />
      )
    case AuthState.SignUp:
      return (
        <SignUpForm
          showSignInForm={() => setAuthState(AuthState.SignIn)}
          afterComplete={() => afterComplete()}
        />
      )
    case AuthState.ForgotPassword:
      return (
        <ForgotPasswordForm
          showSignInForm={() => setAuthState(AuthState.SignIn)}
          afterComplete={() => afterComplete()}
        />
      )
    default:
      return <div>Not implemented</div>
  }
}

// biome-ignore lint/style/noDefaultExport: ...
export default AuthForm
