import type React from "react"
import { useMemo, useState } from "react"
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom"

import { CloseIcon, HamburgerIcon, SettingsIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react"
import { AuthState } from "common-utils"
import { AiOutlineLogout, AiOutlineSetting, AiOutlineUser } from "react-icons/ai"
import { FaCircle, FaHandshake } from "react-icons/fa"

import { UserAvatar } from "~/components/molecules"
import SignInModal from "~/components/organisms/SignInModal"
import { useUser } from "~/containers"
import { useJobSeekerMeQuery } from "~/generated/graphql"
import useUnreadItemsCount from "~/hooks/useUnreadItemsCount"

import logo from "./logo.png"

import type { LinkProps } from "@chakra-ui/react"

// localhost では正しくオウンドメディアに遷移しないため、外部リンク扱いで絶対パスを指定する
const CONTENTS_LINK = "https://rikeimatch.com/contents/"

type LinkType = {
  to: string
  text: string
  unread?: boolean
}

const InternalOnlyLinks: LinkType[] = []

type NavLinkProps = LinkProps & {
  children: React.ReactNode
  to: string
}

const NavLink = (props: NavLinkProps) => {
  const { to, children, isExternal } = props
  const routerProps = isExternal ? { href: to } : { as: RouterLink }

  return (
    <Link
      px={2}
      py={1}
      rounded="md"
      isExternal={isExternal}
      to={to}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.200", "gray.700"),
      }}
      {...routerProps}
    >
      {children}
    </Link>
  )
}

const UserLinks = () => {
  const navigate = useNavigate()
  const { isOpen, onToggle } = useDisclosure()
  const { user, logout, image } = useUser()
  const [authState, setAuthState] = useState<AuthState.SignIn | AuthState.SignUp>(AuthState.SignIn)

  const handleLogout = async () => {
    await logout()
    navigate("/")
  }

  return user ? (
    <Menu>
      <MenuButton as={Button} rounded="full" variant="link" cursor="pointer">
        <UserAvatar
          name={user?.attributes?.family_name}
          imageKey={image}
          size="md"
          sx={{ height: "40px", width: "40px" }}
        />
      </MenuButton>
      <MenuList>
        <Link
          to="/profile"
          as={RouterLink}
          _hover={{
            textDecoration: "none",
          }}
        >
          <MenuItem sx={{ p: 4, fontSize: 16, fontWeight: 600 }}>
            <AiOutlineUser />
            <Text sx={{ ml: 2 }}>プロフィール</Text>
          </MenuItem>
        </Link>

        <Link
          to="/settings"
          as={RouterLink}
          _hover={{
            textDecoration: "none",
          }}
        >
          <MenuItem sx={{ p: 4, fontSize: 16, fontWeight: 600 }}>
            <SettingsIcon />
            <Text sx={{ ml: 2 }}>LINEアカウント連携</Text>
          </MenuItem>
        </Link>

        <Link
          href="https://forms.gle/SKeJMpyy4Kv4DfB66"
          isExternal
          _hover={{
            textDecoration: "none",
          }}
        >
          <MenuItem sx={{ p: 4, fontSize: 16, fontWeight: 600 }}>
            <FaHandshake />
            <Text sx={{ ml: 2 }}>お祝いギフト申請</Text>
          </MenuItem>
        </Link>

        <Link
          to="/account_settings"
          as={RouterLink}
          _hover={{
            textDecoration: "none",
          }}
        >
          <MenuItem sx={{ p: 4, fontSize: 16, fontWeight: 600 }}>
            <AiOutlineSetting />
            <Text sx={{ ml: 2 }}>アカウント設定</Text>
          </MenuItem>
        </Link>

        <MenuItem onClick={handleLogout} sx={{ p: 4, fontSize: 16, fontWeight: 600 }}>
          <AiOutlineLogout />
          <Text sx={{ ml: 2 }}>ログアウト</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  ) : (
    <>
      <Button
        display={{ md: "inline-flex" }}
        fontSize="sm"
        fontWeight={600}
        color="white"
        bg="pink.400"
        onClick={() => {
          setAuthState(AuthState.SignUp)
          onToggle()
        }}
        _hover={{
          bg: "pink.300",
        }}
      >
        登録
      </Button>
      <Button
        display={{ md: "inline-flex" }}
        fontSize="sm"
        fontWeight={600}
        color="white"
        bg="pink.400"
        onClick={() => {
          setAuthState(AuthState.SignIn)
          onToggle()
        }}
        _hover={{
          bg: "pink.300",
        }}
      >
        ログイン
      </Button>
      <SignInModal isOpen={isOpen} onClose={onToggle} defaultAuthState={authState} />
    </>
  )
}

const CambiaHeader: React.FC = () => {
  const location = useLocation()
  const { isOpen, onToggle } = useDisclosure()
  const { user } = useUser()
  const { data } = useJobSeekerMeQuery({
    skip: !user,
  })
  const { hasUnreadJobLikes, hasUnreadMessages, hasUnreadScouts } = useUnreadItemsCount()
  // 他ページからリダイレクトされた時に、locationの更新が行われないため、ページパスが変更されるたびにリンクの表示を更新する
  // プロフィール新規登録画面ではヘッダーを表示しないため
  // React.useEffect(() => {}, [location.pathname])
  const isCreateProfilePage = useMemo(
    () => location.pathname === "/profile/new",
    [location.pathname],
  )

  const links = useMemo(() => {
    if (!user || isCreateProfilePage) {
      return []
    }

    const items = [
      // { to: '/jobs', text: '求人一覧' },
      { to: "/topics", text: "募集特集", unread: false },
      { to: "/messages", text: "メッセージ", unread: hasUnreadMessages || hasUnreadScouts },
      { to: "/likes", text: "興味あり", unread: hasUnreadJobLikes },
      // { to: '/contents', text: '就活に役立つコンテンツ集' },
      { to: "/interview", text: "AI面接診断" },
      // { to: "/campaign", text: "友人紹介キャンペーン" },
    ]

    if (data?.jobSeekerMe?.isInternal) {
      return [...items, ...InternalOnlyLinks]
    }

    return items
  }, [user, data, hasUnreadJobLikes, hasUnreadMessages, hasUnreadScouts, isCreateProfilePage])

  return (
    <Box
      bg="white"
      px={4}
      position="sticky"
      zIndex={100}
      top={0}
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
      }}
    >
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <IconButton
          size="md"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Open Menu"
          display={{ md: isOpen ? "inherit" : "none" }}
          onClick={onToggle}
        />
        <HStack spacing={8} alignItems="center">
          <Box>
            <Image src={logo} h="40px" />
          </Box>
          <HStack as="nav" spacing={4} display={{ base: "none", md: "flex" }}>
            {links.map((link) => (
              <NavLink key={link.to} to={link.to}>
                <Text fontWeight={600} sx={{ position: "relative" }}>
                  {link.text}
                  <FaCircle
                    color="red"
                    size="10px"
                    visibility={link.unread ? "visible" : "hidden"}
                    style={{ position: "absolute", top: "-2px", right: "-10px" }}
                  />
                </Text>
              </NavLink>
            ))}
            {user && (
              <NavLink to={CONTENTS_LINK} isExternal>
                <Text fontWeight={600}>就活に役立つコンテンツ集</Text>
              </NavLink>
            )}
          </HStack>
        </HStack>
        {!isCreateProfilePage && (
          <HStack spacing={8} alignItems="center">
            <UserLinks />
          </HStack>
        )}
      </Flex>

      {isOpen ? (
        <Box pb={4}>
          <Stack as="nav" spacing={4}>
            {links.map((link) => (
              <NavLink key={link.to} to={link.to}>
                <Box onClick={onToggle}>{link.text}</Box>
              </NavLink>
            ))}
            <NavLink to={CONTENTS_LINK} isExternal>
              <Text>就活に役立つコンテンツ集</Text>
            </NavLink>
          </Stack>
        </Box>
      ) : null}
    </Box>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default CambiaHeader
