import { useMediaQuery } from "@chakra-ui/react"

const useMedia = (): { isMobile: boolean } => {
  const [isMobile] = useMediaQuery("(max-width: 768px)")

  return { isMobile }
}

// biome-ignore lint/style/noDefaultExport: ...
export default useMedia
