import type { FC } from "react"
import React from "react"

import { Avatar, Image, Skeleton, SkeletonCircle } from "@chakra-ui/react"

import { getObject } from "~/components/atoms"

import noImage from "./noImage.png"

import type { AvatarProps, ImageProps } from "@chakra-ui/react"

const useS3Image = (imageKey: string | undefined) => {
  const [src, setSrc] = React.useState<string | undefined>(undefined)
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    if (!imageKey) {
      return
    }
    if (/http/i.test(imageKey)) {
      setSrc(imageKey)

      return
    }

    setLoading(true)
    const fetchImage = () => {
      getObject(imageKey)
        .then((imageSrc) => setSrc(imageSrc || undefined))
        .catch((_e) => setSrc(undefined))
      setLoading(false)
    }
    void fetchImage()
  }, [imageKey])

  return { src, loading }
}

const S3Image: FC<
  ImageProps & {
    imageKey: string | undefined
  }
> = ({ imageKey, ...props }) => {
  const { src, loading } = useS3Image(imageKey)

  return loading ? <Skeleton /> : <Image {...props} src={src} fallbackSrc={noImage} />
}

export const UserAvatar: FC<
  AvatarProps & {
    imageKey: string | undefined
  }
> = ({ imageKey, name, size = "sm", ...props }) => {
  const { src, loading } = useS3Image(imageKey)

  return loading ? (
    <SkeletonCircle />
  ) : (
    <Avatar src={src} size={size} name={imageKey ? undefined : name} {...props} />
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default S3Image
