import type React from "react"

import { Box, FormControl, Stack, Text } from "@chakra-ui/react"
import { MailSettingKind } from "rikeimatch-graphql"

import { useJobSeekerUpdateJobSeekerMailSettingMutation } from "~/generated/graphql"

import { notifySaveFailure, notifySaveSuccess } from "../atoms"
import FormTooltip from "../molecules/FormTooltip"
import { MailSettingToggleButton } from "../molecules/MailSettingToggleButton"

import type { JobSeekerMailSetting } from "rikeimatch-graphql"

const MailSettingTypes: { type: MailSettingKind; displayedTitle: string }[] = [
  // メールリスト
  { type: MailSettingKind.MailList, displayedTitle: "運営会社からのお知らせ" },
]

const MailSettingsForm: React.FC<{
  jobSeekerMailSettings: JobSeekerMailSetting[]
}> = ({ jobSeekerMailSettings }) => {
  const [updateMailSettings] = useJobSeekerUpdateJobSeekerMailSettingMutation()
  const handleToggle = async (type: MailSettingKind, currentDisallowed: boolean) => {
    await updateMailSettings({
      variables: {
        input: {
          disallowed: !currentDisallowed,
          kind: type,
        },
      },
      onCompleted: () => {
        notifySaveSuccess()
      },
      onError: () => {
        notifySaveFailure()
      },
    })
  }

  return (
    <Stack px={4} py={5} spacing={6} p={{ sm: 8 }}>
      <Box display="flex" alignItems="center">
        <Text fontSize="lg" fontWeight="bold">
          メール受信設定
        </Text>
        <FormTooltip>
          リケイマッチ運営より新着募集などの役立つ情報を、メール通知より受け取ることができます。オフの場合でも、企業のスカウトやメッセージはメール通知が届きます。
        </FormTooltip>
      </Box>
      <FormControl display="flex" alignItems="center">
        {MailSettingTypes.map(({ type, displayedTitle }) => {
          const setting = jobSeekerMailSettings.find((setting) => setting.kind === type) // 一度もメール通知設定を行っていないユーザーは、JobSeekerMailSettingのレコードが存在しないため、初期設定の通知オンであるfalseを渡す
          const disallowed = setting ? setting.disallowed : false

          return (
            <Box display="flex" alignItems="center" key={type}>
              <MailSettingToggleButton
                mailSettingType={type}
                displayedTitle={displayedTitle}
                disallowed={disallowed}
                handleToggle={() => handleToggle(type, disallowed)}
              />
            </Box>
          )
        })}
      </FormControl>
    </Stack>
  )
}

export { MailSettingsForm }
