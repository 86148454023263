import { initReactI18next } from "react-i18next"

import { domainMessages } from "common-utils"
import i18n from "i18next"

const resources = {
  en: {
    translation: {
      spots: "Spots",
      "Welcome to React": "Welcome to React and react-i18next",
    },
  },
  fr: {
    translation: {
      "Welcome to React": "Bienvenue à React et react-i18next",
    },
  },
  ja: {
    translation: {
      ...domainMessages.resources,
    },
  },
}

void i18n.use(initReactI18next).init({
  resources,
  lng: "ja",
  interpolation: {
    escapeValue: false,
  },
})

// biome-ignore lint/style/noDefaultExport: ...
export default i18n
