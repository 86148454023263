import { Button } from "@chakra-ui/react"

import LineButton from "./LineButton"

import type { ButtonProps } from "@chakra-ui/react"

type Props = ButtonProps & {
  hasLinked: boolean | undefined
  isFollowing: boolean | undefined
  hideUnlink?: boolean | undefined
}

const buttonText = ({ hasLinked, isFollowing, hideUnlink }: Props) => {
  if (!hasLinked) {
    return "連携する"
  }
  if (!isFollowing) {
    return <a href={import.meta.env.VITE_LINE_ADD_FRIEND_URL}>友だち追加</a>
  }
  if (hideUnlink) {
    return "友だち追加済み"
  }

  return "解除する"
}

const LineOrUnlinkButton = (props: Props) => {
  const { hasLinked, isFollowing, hideUnlink, ...rest } = props

  return isFollowing && !hideUnlink ? (
    <Button colorScheme="red" variant="outline" {...rest}>
      {buttonText({ hasLinked, isFollowing, hideUnlink })}
    </Button>
  ) : (
    <LineButton {...rest}>{buttonText({ hasLinked, isFollowing, hideUnlink })}</LineButton>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default LineOrUnlinkButton
