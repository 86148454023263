import type { SubmitHandler } from "react-hook-form"
import { useForm } from "react-hook-form"

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import z from "zod"

import AvatarUploader from "~/components/molecules/AvatarUploader"
import { useJobSeekerCreateReferralCommentMutation } from "~/generated/graphql"

type ReferralCommentsFormInput = {
  avatar?: string
  name: string
  organization: string
  relationship: string
  comment: string
  jobSeekerId: string
}

const schema = z.object({
  avatar: z.string().url().optional(),
  name: z
    .string({
      required_error: "名前を入力してください",
    })
    .min(1, "1文字以上入力してください。")
    .max(100),
  organization: z.string().min(1, "1文字以上入力してください。").max(100),
  relationship: z.string().min(1, "1文字以上入力してください。").max(100),
  comment: z.string().min(1, "1文字以上入力してください。").max(1000),
  jobSeekerId: z.string(),
})

type PropsType = {
  jobSeekerId: string
  handleAfterSubmit: () => void
  handleClose: () => void
}

const ReferralCommentsForm = ({ jobSeekerId, handleAfterSubmit, handleClose }: PropsType) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ReferralCommentsFormInput>({
    mode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: {
      jobSeekerId,
    },
  })

  const [createReferralComment] = useJobSeekerCreateReferralCommentMutation()

  const onSubmit: SubmitHandler<ReferralCommentsFormInput> = async (input) => {
    await createReferralComment({
      variables: {
        input,
      },
      onCompleted: () => {
        handleAfterSubmit()
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={4}>
        <FormControl isInvalid={!!errors.name} isRequired>
          <FormLabel htmlFor="name">名前</FormLabel>
          <Input type="text" id="name" {...register("name")} />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.organization} isRequired>
          <FormLabel htmlFor="organization">所属</FormLabel>
          <Input type="text" id="organization" {...register("organization")} />
          <Text fontSize="xs" color="gray.500">
            例：〇〇大学〇〇学部〇〇学科、〇〇サークル、〇〇株式会社 など
          </Text>
          <FormErrorMessage>{errors.organization?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.relationship} isRequired>
          <FormLabel htmlFor="relationship">関係性</FormLabel>
          <Input type="text" id="relationship" {...register("relationship")} />
          <Text fontSize="xs" color="gray.500">
            例：友人、サークルの後輩、研究室の担当教員 など
          </Text>
          <FormErrorMessage>{errors.relationship?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.comment} isRequired>
          <FormLabel htmlFor="comment">紹介コメント</FormLabel>
          <Textarea id="comment" {...register("comment")} />
          <Text fontSize="xs" color="gray.500">
            例：〇〇さんは大学で出会った友人です。普段一緒に過ごす中で～～～な一面を見ることが多く、～～～なところにはいつも元気をもらっています。※例文の形式に限らず自由に入力ください！
          </Text>
          <FormErrorMessage>{errors.comment?.message}</FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel sx={{ mb: 4 }}>紹介者の写真（任意）</FormLabel>
          <AvatarUploader
            handleAfterUpload={(uploadPath) => {
              setValue("avatar", uploadPath)
            }}
            helperText="紹介者の写真と一緒に紹介コメントが表示されます。プライベートの様子など、どのような写真でも歓迎です！ "
          />
        </FormControl>

        <Stack sx={{ py: 4 }}>
          <Button colorScheme="blue" type="submit">
            送信
          </Button>
          <Button variant="ghost" onClick={handleClose}>
            戻る
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default ReferralCommentsForm
