import { Link as RouterLink } from "react-router-dom"

import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"

import useUnreadItemsCount from "~/hooks/useUnreadItemsCount"

type Props = {
  handleOnClose: () => void
}

const UnreadMessagesAlertDialog = ({ handleOnClose }: Props) => {
  const { unreadJobLikesCount, unreadMessagesCount, unreadScoutsCount } = useUnreadItemsCount()

  return (
    <Modal isOpen onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>未読のメッセージがあります。</ModalHeader>
        <ModalBody>
          <TableContainer>
            <Table variant="simple">
              <TableCaption sx={{ whiteSpace: "pre-wrap", textAlign: "start" }}>
                上記の確認ボタンよりご参照ください。
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>種類</Th>
                  <Th isNumeric>件数</Th>
                  <Th isNumeric>確認</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>興味あり</Td>
                  <Td isNumeric>{unreadJobLikesCount}件</Td>
                  <Td isNumeric>
                    <Link as={RouterLink} to="/likes" textDecoration="none">
                      <Button size="sm" colorScheme="blue" onClick={handleOnClose}>
                        確認
                      </Button>
                    </Link>
                  </Td>
                </Tr>
                <Tr>
                  <Td>メッセージ</Td>
                  <Td isNumeric>{unreadMessagesCount}件</Td>
                  <Td isNumeric>
                    <Link as={RouterLink} to="/messages" textDecoration="none">
                      <Button size="sm" colorScheme="blue" onClick={handleOnClose}>
                        確認
                      </Button>
                    </Link>
                  </Td>
                </Tr>
                <Tr>
                  <Td>スカウト</Td>
                  <Td isNumeric>{unreadScoutsCount}件</Td>
                  <Td isNumeric>
                    <Link as={RouterLink} to="/messages" textDecoration="none">
                      <Button size="sm" colorScheme="blue" onClick={handleOnClose}>
                        確認
                      </Button>
                    </Link>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Stack direction="row" spacing={2}>
            <Button variant="ghost" onClick={handleOnClose}>
              閉じる
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default UnreadMessagesAlertDialog
