import { useEffect, useRef } from "react"

import { Box, Stack, Text } from "@chakra-ui/react"
import { FEEDBACK_PROMPT } from "common-utils"
import { ChatGptMessageRole } from "rikeimatch-graphql"

import { UserAvatar } from "~/components/molecules"
import { useUser } from "~/containers"
import type { MessageType } from "~/hooks/useChatGpt"

// FIXME: import 方法として微妙なので直したい

type Props = {
  messages: MessageType[]
  streamingAnswer?: string
}

const InterviewMessages = ({ messages, streamingAnswer }: Props) => {
  const { user, image } = useUser()
  const endOfMessagesRef = useRef<HTMLDivElement>(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [messages, streamingAnswer])

  return (
    <Stack spacing={8} sx={{ flexGrow: 1, overflowY: "auto", padding: "20px" }}>
      {messages.map((message) => (
        <Stack
          key={Math.random()}
          sx={{ display: message.content === FEEDBACK_PROMPT ? "none" : "inherit" }}
        >
          {message.role === ChatGptMessageRole.User && (
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <UserAvatar
                name={user?.attributes?.family_name}
                imageKey={image}
                size="md"
                sx={{ height: "40px", width: "40px" }}
              />
              <Text sx={{ fontWeight: "bold" }}>あなた</Text>
            </Stack>
          )}
          {message.role === ChatGptMessageRole.Assistant && (
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <UserAvatar imageKey="" size="md" sx={{ height: "40px", width: "40px" }} />
              <Text sx={{ fontWeight: "bold" }}>AI面接官</Text>
            </Stack>
          )}
          <Box
            sx={{
              bg: message.role === "assistant" ? "blue.100" : "green.100",
              p: "20px",
              borderRadius: "md",
              whiteSpace: "pre-wrap",
            }}
          >
            <Text>{message.content}</Text>
          </Box>
        </Stack>
      ))}
      {streamingAnswer && (
        <Stack>
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <UserAvatar imageKey="" size="md" sx={{ height: "40px", width: "40px" }} />
            <Text sx={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>AI面接官</Text>
          </Stack>
          <Box bg="gray.100" p={3} borderRadius="md" sx={{ whiteSpace: "pre-wrap" }}>
            <Text>{streamingAnswer}</Text>
          </Box>
        </Stack>
      )}
      <div ref={endOfMessagesRef} />
    </Stack>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default InterviewMessages
