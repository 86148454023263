import { useState } from "react"

import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { BsLightbulb } from "react-icons/bs"
import { ChatGptMessageRole } from "rikeimatch-graphql"

import useChatGpt from "~/hooks/useChatGpt"

import FormTooltip from "./FormTooltip"

type Props = {
  studyKeywords?: string
  setValue: (value: string) => void
}

const GenerateStudyDetailButton = ({ studyKeywords, setValue }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const disabled = !studyKeywords || studyKeywords.length === 0

  const { sendMessage, stop, answer, loading, error } = useChatGpt()

  const handleOnClick = () => {
    if (disabled) {
      return
    }

    sendMessage([
      {
        role: ChatGptMessageRole.System,
        content:
          "受け取ったキーワードを使用して、「大学で現在勉強していること」を200~400字程度で作成してください。",
      },
      {
        role: ChatGptMessageRole.User,
        content: studyKeywords,
      },
    ])
    setIsOpen(true)
  }

  return (
    <Stack
      onBlur={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      direction="row"
      spacing={1}
    >
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placement="right"
        closeOnBlur={false}
      >
        <Tooltip label="1つ以上の学習タグを入力する必要があります。" isDisabled={!disabled}>
          <Box>
            <PopoverTrigger>
              <Button
                variant="ghost"
                size="sm"
                onClick={handleOnClick}
                isLoading={loading}
                leftIcon={<BsLightbulb />}
                colorScheme="yellow"
                isDisabled={disabled}
              >
                学習内容（詳細）を自動作成
              </Button>
            </PopoverTrigger>
          </Box>
        </Tooltip>
        <Portal>
          <PopoverContent
            sx={{
              outline: 0,
              maxW: { base: "300", md: "500" },
              minW: { base: "200", md: "400" },
              zIndex: 1000000,
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              WebkitTapHighlightColor: "red !important",
              bgColor: "#FFFFEE",
            }}
          >
            <PopoverArrow />
            <PopoverHeader>AIによる文章案</PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              {error ? (
                <Text sx={{ fontSize: 14 }}>エラーが発生しました。再度実行してください。</Text>
              ) : (
                <Text sx={{ fontSize: 14 }}>{answer}</Text>
              )}
            </PopoverBody>
            <PopoverFooter>
              {error && (
                <Button
                  size="sm"
                  onClick={() => {
                    setIsOpen(false)
                    handleOnClick()
                  }}
                  colorScheme="blue"
                >
                  再実行する
                </Button>
              )}
              {!error && (
                <Button
                  size="sm"
                  onClick={() => {
                    if (answer) {
                      setValue(answer)
                    }
                    setIsOpen(false)
                    stop()
                  }}
                  colorScheme="blue"
                >
                  使用する
                </Button>
              )}
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  setIsOpen(false)
                  stop()
                }}
              >
                閉じる
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
      <FormTooltip>
        学習タグを複数入力してからクリックすると、AIリケイサポーターがあなたの学んだ内容を自動作成します。
      </FormTooltip>
    </Stack>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default GenerateStudyDetailButton
