import type React from "react"
import type { ReactNode } from "react"

import { GridItem } from "@chakra-ui/react"

const FormContainer: React.FC<{ children: ReactNode }> = ({ children }) => (
  <GridItem mt={[4, null, 0]} mb={[10, null, 0]} colSpan={{ md: 3 }}>
    {children}
  </GridItem>
)

// biome-ignore lint/style/noDefaultExport: ...
export default FormContainer
