import { InfoOutlineIcon } from "@chakra-ui/icons"
import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react"
import { formatISODatetime } from "common-utils"
import { ChatGptThreadKind } from "rikeimatch-graphql"

import type {
  JobSeekerAiInterviewHistoriesQuery,
  JobSeekerChatGptThreadsQuery,
} from "~/generated/graphql"
import {
  useJobSeekerChatGptThreadsQuery,
  useJobSeekerJobInterviewsAllQuery,
} from "~/generated/graphql"

import ResponsiveTooltip from "../molecules/ResponsiveTooltip"

type ThreadType = JobSeekerChatGptThreadsQuery["chatGptThreads"][0]

type PropsType = {
  selectedThread?: ThreadType
  handleSelectThread: (thread: ThreadType) => void
  interviewHistories: JobSeekerAiInterviewHistoriesQuery["aiInterviewHistories"]
}

const InterviewHistories = ({
  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  selectedThread, // TODO: 現在開いている thread をハイライトする
  handleSelectThread,
  interviewHistories,
}: PropsType) => {
  const { data } = useJobSeekerChatGptThreadsQuery()
  const threads = data?.chatGptThreads
  const threadsForInterviewPractice = threads?.filter(
    (thread) => thread.kind === ChatGptThreadKind.InterviewPractice,
  )

  const { data: jobInterviewsData } = useJobSeekerJobInterviewsAllQuery()
  const jobInterviews = jobInterviewsData?.jobInterviewsAll

  return (
    <Grid templateRows="repeat(6, 1fr)" gap={6} sx={{ flexGrow: 1, overflow: "hidden" }} rowGap={4}>
      <GridItem sx={{ borderBottom: "1px solid #e2e8f0" }} rowSpan={2}>
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text sx={{ fontWeight: "bold" }}>公開中の面接</Text>
          <ResponsiveTooltip label="面接診断履歴より3つまで選択可能。企業担当がスカウト送信の際に「質問に対するあなたの回答」を閲覧できるようになります。自身の個性や魅力を更に伝え、スカウト受信率が増加します。">
            <InfoOutlineIcon />
          </ResponsiveTooltip>
        </Stack>
        {interviewHistories && (
          <Box sx={{ pt: 2 }}>
            {interviewHistories.map((interviewHistory) => (
              <Box
                key={interviewHistory.id}
                sx={{
                  pr: 8,
                  py: 2,
                  cursor: "pointer",
                  _hover: { bgColor: "gray.100" },
                  whiteSpace: "nowrap",
                }}
                onClick={() => handleSelectThread(interviewHistory.chatGptThread)}
              >
                {formatISODatetime(interviewHistory.chatGptThread.createdAt)}
              </Box>
            ))}
          </Box>
        )}
      </GridItem>
      <GridItem
        sx={{
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          borderBottom: "1px solid #e2e8f0",
        }}
        rowSpan={2}
      >
        <Text sx={{ fontWeight: "bold" }}>面接診断履歴</Text>
        <Box sx={{ flexGrow: 1, overflowY: "auto", py: 4 }}>
          {threadsForInterviewPractice ? (
            <Stack>
              {threadsForInterviewPractice.map((thread) => (
                <Box
                  key={thread.id}
                  sx={{
                    pr: 8,
                    py: 2,
                    cursor: "pointer",
                    _hover: { bgColor: "gray.100" },
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => handleSelectThread(thread)}
                >
                  {formatISODatetime(thread.createdAt)}
                </Box>
              ))}
            </Stack>
          ) : (
            <Text>履歴がありません。</Text>
          )}
        </Box>
      </GridItem>
      <GridItem sx={{ overflowY: "hidden", display: "flex", flexDirection: "column" }} rowSpan={2}>
        <Text sx={{ fontWeight: "bold" }}>企業の面接履歴</Text>
        <Box sx={{ flexGrow: 1, overflowY: "auto", py: 4 }}>
          {jobInterviews ? (
            <Stack>
              {jobInterviews.map((jobInterview) => (
                <Box
                  key={jobInterview.id}
                  sx={{
                    pr: 8,
                    py: 2,
                    cursor: "pointer",
                    _hover: { bgColor: "gray.100" },
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => handleSelectThread(jobInterview.chatGptThread)}
                >
                  {jobInterview.job.company.name}
                </Box>
              ))}
            </Stack>
          ) : (
            <Text>履歴がありません。</Text>
          )}
        </Box>
      </GridItem>
    </Grid>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default InterviewHistories
