import type React from "react"
import { useEffect, useState } from "react"
import type { PropsWithChildren } from "react"
import { useForm } from "react-hook-form"
import type { SubmitHandler } from "react-hook-form"

import {
  Box,
  Button,
  Flex,
  FormErrorMessage,
  FormHelperText,
  GridItem,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  Textarea,
  VStack,
  useDisclosure,
} from "@chakra-ui/react"
import { Storage } from "aws-amplify"
import { FaPlusCircle } from "react-icons/fa"
import { FiFile } from "react-icons/fi"
import { ImCross } from "react-icons/im"

import { notifySaveFailure, notifySaveSuccess } from "~/components/atoms"
import { FileInput, InputContainer, S3Image } from "~/components/molecules"
import { useUser } from "~/containers"
import {
  useJobSeekerCreateAchievementMutation,
  useJobSeekerDeleteAchievementMutation,
  useJobSeekerMyAchievementsQuery,
  useJobSeekerUpdateAchievementMutation,
} from "~/generated/graphql"

import type { Achievement } from "rikeimatch-graphql"

type AchievementFormInput = {
  title: string
  date: string
  url?: string
  attachment?: string | FileList
  description: string
}

const ImageOverlay: React.FC<PropsWithChildren<{ onClick: () => void }>> = (props) => (
  <Box position="relative">
    <Button
      position="absolute"
      top="10%"
      left="90%"
      transform="translate(-50%, -50%)"
      backgroundColor="#555"
      color="white"
      fontSize="16px"
      padding="10px"
      border="none"
      borderRadius="5px"
      onClick={props.onClick}
    >
      <ImCross />
    </Button>
    {props.children}
  </Box>
)

const ImageWithText: React.FC<
  PropsWithChildren<{
    onClick: () => void
    text: React.ReactNode | string
  }>
> = (props) => (
  <Box position="relative">
    <Button
      position="absolute"
      top="20%"
      left="50%"
      transform="translate(-50%, -50%)"
      backgroundColor="#555"
      color="white"
      fontSize="16px"
      padding="10px"
      border="none"
      borderRadius="5px"
      onClick={props.onClick}
    >
      {props.text}
    </Button>
    {props.children}
  </Box>
)

const AchievementForm: React.FC<{
  afterSubmit: () => void
  achievement?: Achievement | undefined
}> = ({ afterSubmit, achievement }) => {
  const { identityId } = useUser()
  const { id, __typename, ...achievementAttrs } = achievement || {}
  const [uploadedImage, setUploadedImage] = useState<undefined | string | null>(
    achievement?.attachment || undefined,
  )
  const [execCreateAchievement, { error: createError }] = useJobSeekerCreateAchievementMutation()
  const [execUpdateAchievement, { error: updateError }] = useJobSeekerUpdateAchievementMutation()
  const [execDeleteAchievement, { error: deleteError }] = useJobSeekerDeleteAchievementMutation()

  const handleDelete = async () => {
    if (!id) {
      return
    }
    const result = await execDeleteAchievement({
      variables: { id },
    })

    if (deleteError || !result.data?.deleteAchievement.success) {
      notifySaveFailure()

      return
    }

    notifySaveSuccess()
    afterSubmit()
  }

  const {
    register,
    handleSubmit,
    resetField,
    formState: { isDirty, isSubmitting, errors },
    watch,
    setError,
  } = useForm<AchievementFormInput>({
    mode: "onBlur",
    // @ts-ignore
    defaultValues: {
      ...achievementAttrs,
      url:
        "url" in achievementAttrs && typeof achievementAttrs.url === "string"
          ? achievementAttrs.url
          : undefined,
    },
  })

  const resetImage = () => {
    resetField("attachment")
    setUploadedImage(null)
  }

  useEffect(() => {
    // TODO: 急に出てきた
    // @ts-ignore
    const subscription = watch(async (value, { name }) => {
      if (!identityId || name !== "attachment") {
        return
      }

      const { attachment } = value
      if (attachment && typeof attachment !== "string") {
        const file = attachment.item(0) == null ? undefined : attachment.item(0)
        if (file) {
          const { key } = (await Storage.put(`achievements-${file.name}`, file, {
            contentType: file.type,
            level: "protected",
          })) as { key: string | null }
          if (!key) {
            return
          }

          const uploadPath = `${identityId}/${key}`
          setUploadedImage(uploadPath)
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, identityId])

  const onSubmit: SubmitHandler<AchievementFormInput> = async (vars) => {
    const input = { ...vars, attachment: uploadedImage }
    if (!input.url && !input.attachment) {
      setError("attachment", {
        type: "deps",
        message: "画像かURLを指定してください",
      })
      setError("url", {
        type: "deps",
        message: "画像かURLを指定してください",
      })

      return
    }

    if (id) {
      await execUpdateAchievement({ variables: { input, id } })
    } else {
      await execCreateAchievement({ variables: { input } })
    }
    if (createError || updateError) {
      notifySaveFailure()
    } else {
      notifySaveSuccess()
      afterSubmit()
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack>
        <InputContainer name="attachment" label="画像" invalid={!!errors.attachment}>
          <FileInput register={register("attachment")} accept="image/*">
            <Button leftIcon={<Icon as={FiFile} />}>アップロード</Button>
          </FileInput>
          <FormErrorMessage color="#b8526b">
            {errors.attachment?.type === "deps" && errors.attachment?.message}
          </FormErrorMessage>
        </InputContainer>
        {uploadedImage && (
          <ImageOverlay onClick={resetImage}>
            <S3Image imageKey={uploadedImage} paddingBottom="10px" maxH="180px" />
          </ImageOverlay>
        )}
      </VStack>
      <InputContainer name="title" label="タイトル" invalid={!!errors.title}>
        <Input
          isRequired
          isInvalid={!!errors.title}
          {...register("title", { required: true, maxLength: 30 })}
        />
        <FormHelperText>30文字以内</FormHelperText>
      </InputContainer>
      <InputContainer name="date" label="日付" invalid={!!errors.date}>
        <Input
          type="date"
          isRequired
          isInvalid={!!errors.date}
          {...register("date", {
            required: true,
            validate: (value) => !Number.isNaN(Date.parse(value)),
          })}
        />
      </InputContainer>
      <InputContainer name="url" label="URL" invalid={!!errors.url}>
        <Input isInvalid={!!errors.url} type="url" {...register("url")} />
        <FormErrorMessage color="#b8526b">
          {errors.url?.type === "deps" && errors.url?.message}
        </FormErrorMessage>
      </InputContainer>
      <InputContainer name="description" label="詳細" invalid={!!errors.description}>
        <Textarea
          isRequired
          isInvalid={!!errors.description}
          {...register("description", { required: true, maxLength: 500 })}
        />
        <FormHelperText>500文字以内</FormHelperText>
      </InputContainer>

      <Flex paddingTop="10px" justifyContent="space-between">
        <Button colorScheme="blue" isLoading={isSubmitting} isDisabled={!isDirty} type="submit">
          保存
        </Button>
        {id && (
          <Button
            colorScheme="red"
            isLoading={isSubmitting}
            type="button"
            onClick={() => handleDelete()}
          >
            削除
          </Button>
        )}
      </Flex>
    </form>
  )
}

AchievementForm.defaultProps = {
  achievement: undefined,
}

const AchievementModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  achievement?: Achievement | undefined
}> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{`制作物を${props.achievement ? "編集" : "追加"}する`}</ModalHeader>
      <ModalBody borderRadius="5px">
        <ModalCloseButton zIndex={2} />
        <Stack
          direction="column"
          spacing="30px"
          padding="20px"
          divider={<StackDivider borderColor="gray.200" />}
        >
          <AchievementForm achievement={props.achievement} afterSubmit={props.onClose} />
        </Stack>
      </ModalBody>
    </ModalContent>
  </Modal>
)

AchievementModal.defaultProps = {
  achievement: undefined,
}

const Achievements: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure()
  const {
    data: { jobSeekerMe: jobSeeker } = {},
    refetch,
  } = useJobSeekerMyAchievementsQuery()
  const [current, setCurrent] = useState<Achievement | undefined>()

  return (
    <Box>
      <SimpleGrid columns={{ xs: 1, sm: 2, md: 3 }} spacing={20}>
        {jobSeeker?.achievements.length === 0 ? (
          <GridItem colSpan={{ md: 2 }}>
            <Text>制作物を登録してみましょう！</Text>
          </GridItem>
        ) : (
          jobSeeker?.achievements.map((achievement) => (
            <GridItem key={achievement.id} margin="auto">
              <ImageWithText
                onClick={() => {
                  setCurrent(achievement)
                  onToggle()
                }}
                text={
                  achievement.title.length > 12
                    ? `${achievement.title.slice(0, 10)}...`
                    : achievement.title
                }
              >
                <S3Image
                  imageKey={achievement.attachment || undefined}
                  margin="auto"
                  maxH="240px"
                />
              </ImageWithText>
            </GridItem>
          ))
        )}
        <GridItem margin="auto">
          <Button
            h="72px"
            w="72px"
            onClick={() => {
              setCurrent(undefined)
              onToggle()
            }}
          >
            <FaPlusCircle size="36px" />
          </Button>
        </GridItem>
      </SimpleGrid>
      <AchievementModal
        {...{
          achievement: current,
          isOpen,
          onClose: () => {
            onToggle()
            void refetch()
          },
        }}
      />
    </Box>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default Achievements
