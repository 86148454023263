import type { FC } from "react"

import { Box, Link, Stack, Text, VStack } from "@chakra-ui/react"

const currentYear = new Date().getFullYear()

const CambiaFooter: FC = () => (
  <VStack bgColor="#0a94cf" color="white" padding="24px 2% 16px" textAlign="center" id="footer">
    <Box>
      <Stack
        spacing={{ base: 2, md: 5 }}
        direction={{ base: "column", md: "row" }}
        sx={{
          fontSize: { base: "sm", md: "md" },
        }}
      >
        <Link isExternal href="https://cambiainc.co.jp/recruiting">
          採用企業様はこちら
        </Link>
        <Link isExternal href="https://rikeimatch.com/contents/">
          就活に役立つコンテンツ集
        </Link>
        <Link isExternal href="https://www.instagram.com/rikeimatch">
          Instagram
        </Link>
        <Link isExternal href="https://x.com/Rikeimatch">
          X（Twitter）
        </Link>
        <Link
          isExternal
          href="https://useful-radio-75c.notion.site/Q-A-894cbddffec94deb8c5e296fbb9148a6"
        >
          リケイマッチ Q&A
        </Link>
        <Link isExternal href="https://cambiainc.co.jp/">
          運営会社
        </Link>
        <Link href="/tos.html">利用規約</Link>
        <Link href="/privacy.html">プライバシーポリシー</Link>
      </Stack>
    </Box>
    <Box sx={{ py: 2 }}>
      <Text>©{currentYear} Cambia</Text>
    </Box>
  </VStack>
)

// biome-ignore lint/style/noDefaultExport: ...
export default CambiaFooter
