import { Storage } from "aws-amplify"

const getObject = async (keyWithId: string): Promise<string | null> => {
  const [identityId, key] = keyWithId.split("/")
  const options = Object.fromEntries(
    Object.entries({
      identityId,
      level: "protected",
    }).filter(([_, v]) => v != null),
  )

  return (await Storage.get(key, options)) as unknown as string
}

const getPublicObject = async (key: string): Promise<string | null> =>
  (await Storage.get(key)) as unknown as string

export { getObject, getPublicObject }
