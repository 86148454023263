import { type ReactNode, useCallback, useEffect, useState } from "react"

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react"

import useMedia from "~/hooks/useMedia"

type TemplateId =
  | "experiment-individual"
  | "experiment-team"
  | "programming"
  | "with-theme"
  | "without-theme"
type TemplateKind = "studyDetail" | "researchDetail"
type Templates = Record<TemplateKind, Template[]>
type Template = Record<"label" | "text", string> & { id: TemplateId }

const TEMPLATES: Templates = {
  studyDetail: [
    {
      id: "experiment-individual",
      label: "学生実験（個人）",
      text:
        "学生実験で、◯◯◯◯について取り組みました。" +
        "◯◯系の各構成要素が◯◯系に与える影響を確認すること、数理モデリングを行い、" +
        "実験結果を数値にて解析すること、実際に◯◯器を設計することを行いました。" +
        "〇〇〇〇の課題に突き当たる事もありましたが、友人や教授への質問、" +
        "大学図書館の活用により、理解を深めながら取り組みました。" +
        "一連の学習を通して〇〇について学ぶことができました。",
    },
    {
      id: "experiment-team",
      label: "学生実験（チーム）",
      text:
        "私は大学の学業の中で特に力を入れて取り組んだ事は実験科目です。" +
        "この実験科目では◯◯等の〇〇や〇〇を観測し、その結果から何が分かるか、" +
        "あるいはどのような特徴があり物に応用されているのかを考察しております。" +
        "この測定において重要なのは、班の仲間と協力し合い手分けして取り組む事で" +
        "〇〇と〇〇の誤差を少なく、あるいは違いが出る事を正確に測ること。" +
        "そして、それをまとめる力が必要不可欠であり、毎回の実験で苦労しつつ目標を達成してきました。" +
        "この経験から、周囲の方々と手分けして素早く正確に作業を行うべき事を提案、実行に移す力を身につけました。",
    },
    {
      id: "programming",
      label: "プログラミング",
      text:
        "私が学生時代に頑張った事はプログラミングです。〇〇を制作しました。" +
        "プログラムの本やインターネットを利用しながら作り方を調べ、コツコツと製作してきました。" +
        "途中、不具合などありましたが、諦める事なく原因を追究して〇〇を完成させました。" +
        "\n\n■利用可能OS・言語\n【OS】Windows,Linux\n" +
        "【言語、ツール】C,Java,PHP,JavaScript,Vue\n【DB】MySQL,PostgreSQL",
    },
  ],
  researchDetail: [
    {
      id: "with-theme",
      label: "テーマが決まっている場合",
      text:
        "〇〇の〇〇に関して研究をしています。具体的には〇〇〇〇です。" +
        "〇〇〇〇の社会課題に対して、〇〇〇〇の貢献に繋がります。私がこのテーマを選んだ背景は〇〇〇〇です。" +
        "現在は〇〇〇〇に取り組んでおり、この研究を通して〇〇〇〇について学んでいきたいと思います。",
    },
    {
      id: "without-theme",
      label: "テーマが未定の場合",
      text:
        "研究テーマはまだ決まっていませんが、〇〇の分野に興味があるため、〇〇に取り組む予定です。" +
        "興味を持った背景は〇〇〇〇です。研究活動を通して〇〇について更に深く追求して、" +
        "将来的には〇〇においても応用できるよう励みたいと思います。",
    },
  ],
}

type Props = {
  kind: TemplateKind
  onChange: (_text: string) => void
  originalText?: string
}

const ProfileTemplateModal = ({ kind, onChange, originalText = "" }: Props): ReactNode => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedTemplateId, setSelectedTemplateId] = useState<TemplateId>(TEMPLATES[kind][0].id)
  const selectedTemplate = TEMPLATES[kind].find((t) => t.id === selectedTemplateId) as Template
  const [text, setText] = useState<string>("")
  const { isMobile } = useMedia()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const handleOnSubmit = useCallback(
    (action: "overwrite" | "insert"): void => {
      switch (action) {
        case "overwrite":
          onChange(text)
          break
        case "insert":
          onChange(`${originalText}\n\n${text}`)
          break
        default:
          break
      }
      onClose()
    },
    [text, originalText],
  )

  useEffect(() => {
    setText(selectedTemplate.text)
  }, [selectedTemplate])

  return (
    <>
      <Button variant="link" colorScheme="blue" onClick={onOpen} size="sm">
        テンプレートを使用
      </Button>

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>テンプレートから選択</ModalHeader>
          <ModalCloseButton />
          {isMobile ? (
            <Select
              mx={6}
              my={3}
              w="unset"
              value={selectedTemplate.id}
              onChange={(e) => setSelectedTemplateId(e.target.value as TemplateId)}
            >
              {TEMPLATES[kind].map((template) => (
                <option key={template.id} value={template.id}>
                  {template.label}
                </option>
              ))}
            </Select>
          ) : (
            <Stack direction="row" mx={6}>
              {TEMPLATES[kind].map((template) => (
                <Button
                  key={template.id}
                  onClick={() => setSelectedTemplateId(template.id)}
                  colorScheme={template.id === selectedTemplateId ? "blue" : undefined}
                >
                  {template.label}
                </Button>
              ))}
            </Stack>
          )}
          <ModalBody>
            <Textarea size="lg" rows={10} value={text} onChange={(e) => setText(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            {originalText === "" ? (
              <Button colorScheme="blue" mr={3} onClick={() => handleOnSubmit("overwrite")}>
                保存
              </Button>
            ) : (
              <>
                <Button colorScheme="blue" mr={3} onClick={() => handleOnSubmit("overwrite")}>
                  本文を上書き
                </Button>
                <Button colorScheme="blue" mr={3} onClick={() => handleOnSubmit("insert")}>
                  文末に挿入
                </Button>
              </>
            )}
            <Button variant="ghost" onClick={onClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

ProfileTemplateModal.defaultProps = {
  originalText: "",
}

// biome-ignore lint/style/noDefaultExport: ...
export default ProfileTemplateModal
