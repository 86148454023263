import { JobSeekerStatus } from "rikeimatch-graphql"

import { profileStatus } from "./domainMessages"

const jobSeekerStatuses = Object.values(JobSeekerStatus).map((status) => ({
  id: status,
  name: profileStatus[status],
}))

// biome-ignore lint/style/noDefaultExport: ...
export default jobSeekerStatuses
