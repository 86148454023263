import React from "react"

import * as Sentry from "@sentry/react"
import { Auth } from "aws-amplify"

import type { AmplifyUser } from "common-utils"

type UserContext = {
  user: undefined | AmplifyUser | null
  identityId: undefined | string
  login: (_usernameOrEmail: string, _password: string) => Promise<void>
  logout: () => Promise<void>
  changeEmail: (email: string) => void
  loading: boolean
  image: string | undefined
  updateImage: (newImage: string) => Promise<void>
}

const userContextInitValue: UserContext = {
  user: undefined,
  identityId: undefined,
  login: (_usernameOrEmail: string, _password: string) => Promise.resolve(),
  logout: () => Promise.resolve(),
  changeEmail: (_email: string) => null,
  loading: false,
  image: undefined,
  updateImage: (_) => Promise.resolve(),
}

export const UserContext = React.createContext(userContextInitValue)

const UserProvider: React.FC<{
  children: React.ReactElement
}> = ({ children }) => {
  const [user, setUser] = React.useState<AmplifyUser | undefined | null>()
  const loading = user === undefined
  const [identityId, setIdentityId] = React.useState<string | undefined>()
  const [image, setImage] = React.useState<string | undefined>()

  React.useEffect(() => {
    if (user) {
      return
    }
    Auth.currentAuthenticatedUser()
      .then((current: AmplifyUser) => {
        setUser(current)
        setImage(current?.attributes?.picture)
        Sentry.setUser({
          email: current?.username?.toLowerCase(),
          id: current?.userSub,
        })
      })
      .catch(() => {
        setUser(null)
        setImage(undefined)
        Sentry.configureScope((scope) => scope.setUser(null))
      })
  }, [user])
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useEffect(() => {
    if (identityId) {
      return
    }
    Auth.currentCredentials()
      .then((credentials) => setIdentityId(credentials.identityId))
      .catch(() => setIdentityId(undefined))
  }, [identityId, user])

  const login = async (usernameOrEmail: string, password: string) => {
    const result = (await Auth.signIn(usernameOrEmail, password)) as AmplifyUser
    setUser(result)
    setImage(result?.attributes?.picture)
    Sentry.setUser({
      email: usernameOrEmail.toLowerCase(),
      id: result.userSub,
    })
  }
  const updateImage = async (newPicture: string) => {
    await Auth.updateUserAttributes(user, {
      picture: newPicture,
    })
    setImage(newPicture)
  }
  const logout = () =>
    Auth.signOut().then(() => {
      setUser(null)
      Sentry.configureScope((scope) => scope.setUser(null))
    })
  const changeEmail = React.useCallback(
    (email: string) => {
      void Auth.updateUserAttributes(user, { email })
    },
    [user],
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const userLoginLogout = React.useMemo(
    () => ({ user, login, logout, changeEmail, loading }),
    [user, changeEmail, loading],
  )
  const identityIdValue = React.useMemo(() => identityId, [identityId])
  const value = {
    ...userLoginLogout,
    identityId: identityIdValue,
    image,
    updateImage,
  }

  return <UserContext.Provider {...{ value }}>{children}</UserContext.Provider>
}

export const useUser = (): UserContext => {
  const context = React.useContext(UserContext)
  if (context == null) {
    throw new Error("`useUser` must be within a `UserProvider`")
  }

  return context
}

// biome-ignore lint/style/noDefaultExport: ...
export default UserProvider
