import { useRef } from "react"
import type { PropsWithChildren } from "react"
import type { UseFormRegisterReturn } from "react-hook-form"

import { InputGroup } from "@chakra-ui/react"

type FileInputProps = {
  register: UseFormRegisterReturn
  accept?: string
  multiple?: boolean
}

const FileInput = (props: PropsWithChildren<FileInputProps>) => {
  const { register, accept, multiple, children } = props
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { ref, ...rest } = register as {
    ref: (instance: HTMLInputElement | null) => void
  }
  const handleClick = () => inputRef.current?.click()

  return (
    <InputGroup onClick={handleClick}>
      <input
        type="file"
        multiple={multiple}
        hidden
        accept={accept}
        {...rest}
        ref={(e) => {
          ref(e)
          inputRef.current = e
        }}
      />
      {children}
    </InputGroup>
  )
}

FileInput.defaultProps = {
  accept: "*/*",
  multiple: false,
  children: <div />,
}

// biome-ignore lint/style/noDefaultExport: ...
export default FileInput
