import { useState } from "react"
import { Link, useParams } from "react-router-dom"

import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react"

import { useJobSeekerBySlugForReferralCommentsQuery } from "~/generated/graphql"

import { UserAvatar } from "../molecules"
import LoadingSkeleton from "../molecules/LoadingSkeleton"
import ReferralCommentsForm from "../organisms/referralComments/ReferralCommentsForm"
import ThanksPage from "../organisms/referralComments/ThanksPage"

import RikelinkLogo from "./images/rikelink_logo.png"

const CreateReferralComments = () => {
  const [openForm, setOpenForm] = useState(false)
  const [thanksPage, setThanksPage] = useState(false)

  const { slug = "" } = useParams<{ slug: string }>()

  const { data } = useJobSeekerBySlugForReferralCommentsQuery({
    variables: {
      slug,
    },
  })

  const jobSeeker = data?.jobSeekerBySlug
  const school = jobSeeker?.educationHistory?.name
  const name = (jobSeeker?.lastName || "") + (jobSeeker?.firstName || "")

  if (!jobSeeker) {
    return <LoadingSkeleton />
  }

  return (
    <Stack sx={{ maxW: 500, mx: "auto", my: 20, textAlign: "flex-start", px: 8 }} spacing={10}>
      <Link to="/">
        <Box sx={{ height: 45, display: "flex" }}>
          <img src={RikelinkLogo} alt="rikelink-logo" style={{ height: "100%" }} />
        </Box>
      </Link>
      <Stack direction="row" sx={{ textAlign: "left" }} spacing={4}>
        <UserAvatar imageKey={jobSeeker?.image || undefined} size={{ base: "md", md: "lg" }} />
        <Stack spacing={0} sx={{ justifyContent: "center" }}>
          <Text sx={{ fontWeight: "bold" }}>
            {school} {name}さんから紹介コメントの依頼が届いています。
          </Text>
        </Stack>
      </Stack>
      {!openForm && !thanksPage && (
        <Stack spacing={8}>
          <Stack spacing={0}>
            <Heading size="sm">リケリンクとは？</Heading>
            <Text>
              リケリンクとは理系学生ならではのポートフォリオです。そして紹介コメントを通して、履歴書だけでは実現できない
              {name}さんの個性・魅力を引き出していきます。
            </Text>
          </Stack>
          <Stack spacing={0}>
            <Heading size="sm">注意事項</Heading>
            <Text>
              記入された紹介コメントは{name}
              さんのプロフィールに表示されます。個人情報に関わる内容や機密情報の記入はお控えください。
            </Text>
          </Stack>
          <Button onClick={() => setOpenForm(true)} colorScheme="blue">
            開始
          </Button>
        </Stack>
      )}
      {openForm && (
        <ReferralCommentsForm
          jobSeekerId={jobSeeker.id}
          handleAfterSubmit={() => {
            setThanksPage(true)
            setOpenForm(false)
          }}
          handleClose={() => setOpenForm(false)}
        />
      )}
      {thanksPage && <ThanksPage />}
    </Stack>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default CreateReferralComments
