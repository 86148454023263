import type React from "react"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import type { SubmitHandler } from "react-hook-form"

import { Box, FormHelperText, HStack, Select, Stack, useColorModeValue } from "@chakra-ui/react"
import { i18n, objectToOptions } from "common-utils"

import { notifySaveFailure, notifySaveSuccess } from "~/components/atoms"
import { InputContainer } from "~/components/molecules"
import { useJobSeekerMeQuery, useJobSeekerUpdateProfileMutation } from "~/generated/graphql"

const prefectureArray = objectToOptions(i18n.prefecturesForDesiredWork)
const desiredCompanySizeOptions = objectToOptions(i18n.desiredCompanySizes)

type DesiredWorkFormInput = {
  desiredJobType1?: string
  desiredJobType2?: string
  desiredJobType3?: string
  desiredWorkLocation1?: string
  desiredWorkLocation2?: string
  desiredWorkLocation3?: string
  desiredCompanySize1?: string
  desiredCompanySize2?: string
  desiredCompanySize3?: string
}

type DesiredCompanySizes = "desiredCompanySize1" | "desiredCompanySize2" | "desiredCompanySize3"

type DesiredWorkLocations = "desiredWorkLocation1" | "desiredWorkLocation2" | "desiredWorkLocation3"

type DesiredJobType = "desiredJobType1" | "desiredJobType2" | "desiredJobType3"

const nonEmpty = (str: string | undefined): str is string => !!str

const DesiredWorkForm: React.FC = () => {
  const { data, loading } = useJobSeekerMeQuery()
  const [updateSeekerProfile, { error }] = useJobSeekerUpdateProfileMutation()
  const cardBg = useColorModeValue("white", "gray.800")
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<DesiredWorkFormInput>({
    mode: "onBlur",
    shouldFocusError: false,
  })
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!loading && !isDirty) {
      const profile = data?.jobSeekerMe
      const defaultValues = {
        desiredJobType1: profile?.desiredJobTypes?.[0],
        desiredJobType2: profile?.desiredJobTypes?.[1],
        desiredJobType3: profile?.desiredJobTypes?.[2],
        desiredWorkLocation1: profile?.desiredWorkLocations?.[0],
        desiredWorkLocation2: profile?.desiredWorkLocations?.[1],
        desiredWorkLocation3: profile?.desiredWorkLocations?.[2],
        desiredCompanySize1: profile?.desiredCompanySizes?.[0],
        desiredCompanySize2: profile?.desiredCompanySizes?.[1],
        desiredCompanySize3: profile?.desiredCompanySizes?.[2],
      }
      reset({
        ...defaultValues,
      })
    }
  }, [loading])
  const onSubmit: SubmitHandler<DesiredWorkFormInput> = async (submittedData) => {
    const desiredJobTypes = [
      submittedData.desiredJobType1,
      submittedData.desiredJobType2,
      submittedData.desiredJobType3,
    ].filter(nonEmpty)
    const desiredWorkLocations = [
      submittedData.desiredWorkLocation1,
      submittedData.desiredWorkLocation2,
      submittedData.desiredWorkLocation3,
    ].filter(nonEmpty)
    const desiredCompanySizes = [
      submittedData.desiredCompanySize1,
      submittedData.desiredCompanySize2,
      submittedData.desiredCompanySize3,
    ].filter(nonEmpty)
    await updateSeekerProfile({
      variables: {
        input: { desiredJobTypes, desiredWorkLocations, desiredCompanySizes },
      },
    })
    if (error) {
      notifySaveFailure()
    } else {
      notifySaveSuccess()
    }
  }

  return (
    <Box shadow="base" rounded={[null, "md"]} overflow={{ sm: "hidden" }}>
      <Stack px={4} py={5} bg={useColorModeValue("white", "gray.700")} spacing={6} p={{ sm: 8 }}>
        <form onBlur={handleSubmit(onSubmit)}>
          <Box w="full" bg={cardBg} rounded="md" overflow="hidden" borderStyle="solid">
            <InputContainer name="desiredJobTypes" label="希望職種" invalid={false}>
              <HStack paddingBottom="10px">
                {(
                  ["desiredJobType1", "desiredJobType2", "desiredJobType3"] as Array<DesiredJobType>
                ).map((selectNo) => (
                  <div key={selectNo}>
                    <Select
                      placeholder="-"
                      isInvalid={!!errors[selectNo]}
                      {...register(selectNo)}
                      id={`label-${selectNo}`}
                    >
                      {i18n.desiredJobTypeOptions.map((jobType) => (
                        <option key={jobType.id} value={jobType.id} id={`select-${selectNo}`}>
                          {jobType.name}
                        </option>
                      ))}
                    </Select>
                    <FormHelperText>{`第${selectNo.slice(-1)}希望`}</FormHelperText>
                  </div>
                ))}
              </HStack>
            </InputContainer>

            <InputContainer
              name="desiredWorkLocations"
              label="希望勤務地（全国可を選ぶと地域問わない企業からスカウトが届きます）"
              invalid={false}
            >
              <HStack paddingBottom="10px">
                {(
                  [
                    "desiredWorkLocation1",
                    "desiredWorkLocation2",
                    "desiredWorkLocation3",
                  ] as Array<DesiredWorkLocations>
                ).map((selectNo) => (
                  <div key={selectNo}>
                    <Select
                      placeholder="-"
                      isInvalid={!!errors[selectNo]}
                      {...register(selectNo)}
                      id={`label-${selectNo}`}
                    >
                      {prefectureArray.map((prefecture) => (
                        <option key={prefecture.id} value={prefecture.id} id={`select-${selectNo}`}>
                          {prefecture.name}
                        </option>
                      ))}
                    </Select>
                    <FormHelperText>{`第${selectNo.slice(-1)}希望`}</FormHelperText>
                  </div>
                ))}
              </HStack>
            </InputContainer>

            <InputContainer name="desiredCompanySizes" label="希望企業規模" invalid={false}>
              <HStack>
                {(
                  [
                    "desiredCompanySize1",
                    "desiredCompanySize2",
                    "desiredCompanySize3",
                  ] as Array<DesiredCompanySizes>
                ).map((selectNo) => (
                  <div key={selectNo}>
                    <Select
                      placeholder="-"
                      isInvalid={!!errors[selectNo]}
                      {...register(selectNo)}
                      id={`label-${selectNo}`}
                    >
                      {desiredCompanySizeOptions.map((companySize) => (
                        <option
                          key={companySize.id}
                          value={companySize.id}
                          id={`select-${selectNo}`}
                        >
                          {companySize.name}
                        </option>
                      ))}
                    </Select>
                    <FormHelperText>{`第${selectNo.slice(-1)}希望`}</FormHelperText>
                  </div>
                ))}
              </HStack>
            </InputContainer>
          </Box>
        </form>
      </Stack>
    </Box>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default DesiredWorkForm
