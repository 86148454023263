import { HStack, Modal, ModalOverlay, Spinner } from "@chakra-ui/react"

const LoadingOverlay = () => (
  <>
    <HStack
      position="absolute"
      top="100%"
      left="50%"
      transform="translate(-50%, -50%)"
      backgroundColor="transparent"
      justifyContent="center"
    >
      <Spinner width="10rem" height="10rem" />
    </HStack>
    <Modal isOpen onClose={() => undefined}>
      <ModalOverlay />
    </Modal>
  </>
)

// biome-ignore lint/style/noDefaultExport: ...
export default LoadingOverlay
