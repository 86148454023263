import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { createConsumer } from "@rails/actioncable"
import * as ActionCable from "@rails/actioncable"
import { Auth } from "aws-amplify"
import { v4 as uuidv4 } from "uuid"

import { webSocketHost } from "~/config"

import type { Channel } from "@rails/actioncable"
import type { ChatGptMessageRole } from "rikeimatch-graphql"

export type MessageType = {
  role: ChatGptMessageRole
  content: string
}

type ResponseType = {
  answer: string
  loading: boolean
  error?: string
}

type SubscriptionType = Channel & { received: (data: ResponseType) => void }

ActionCable.logger.enabled = true

const generateChannelId = () => uuidv4()

const getAuthToken = async () => {
  try {
    const session = await Auth.currentSession()
    const token = session.getAccessToken().getJwtToken()

    return token
  } catch (error) {
    if (error !== "No current user") {
      console.error(error)
    }

    return undefined
  }
}

type Props = {
  onFinished?: (finalOutput: string, threadId?: string) => void
}

const useChatGpt = ({ onFinished } = {} as Props) => {
  const [authToken, setAuthToken] = useState<string | undefined>(undefined)
  const [receivedMessage, setReceivedMessage] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState("")
  const [subscription, setSubscription] = useState<SubscriptionType | undefined>(undefined)
  const cable = useMemo(() => {
    if (authToken) {
      return createConsumer(`${webSocketHost}/cable?token=${authToken}`)
    }

    return undefined
  }, [authToken])
  const [error, setError] = useState<string>()

  const channelId = useRef(generateChannelId())

  const handleFinish = useCallback(
    (finalOutput: string, threadId?: string) => {
      setLoading(false)
      if (onFinished) {
        onFinished(finalOutput, threadId)
      }
    },
    [onFinished],
  )

  const subscribeToChatChannel = useCallback(() => {
    if (!cable) {
      return
    }

    const sub = cable.subscriptions.create(
      { channel: "ChatChannel", channel_id: channelId.current },
      {
        received: (data: ResponseType) => {
          setReceivedMessage(data.answer)
          setLoading(data.loading)
          if (data.error) {
            setError(data.error)
            setLoading(false)
          }
        },
      },
    )
    setSubscription(sub)
  }, [cable])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    subscribeToChatChannel()
  }, [cable, subscribeToChatChannel])

  const handleSend = (messages: MessageType[], threadId?: string) => {
    setLoading(true)
    setText("")

    if (subscription) {
      subscription.unsubscribe()
    }

    channelId.current = generateChannelId()

    if (!cable) {
      return
    }
    const newSubscription = cable.subscriptions.create(
      { channel: "ChatChannel", channel_id: channelId.current },
      {
        received: (data: ResponseType) => {
          setReceivedMessage(data.answer)
          // setLoading(data.loading)
          if (data.loading === false) {
            handleFinish(data.answer, threadId)
          }
          if (data.error) {
            setError(data.error)
            setLoading(false)
          }
        },
      },
    )
    setSubscription(newSubscription)
    newSubscription.perform("chat", {
      channel_id: channelId.current,
      messages,
      thread_id: threadId,
    })
  }

  const handleStop = () => {
    setLoading(false)
    setText("")
    subscription?.unsubscribe()
  }

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAuthToken()
      setAuthToken(token)
    }

    void fetchToken()
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (authToken) {
      subscribeToChatChannel()
    }
  }, [cable, subscribeToChatChannel, authToken])

  useEffect(() => {
    if (!receivedMessage) {
      return
    }

    setText(receivedMessage)
  }, [receivedMessage])

  return {
    sendMessage: handleSend,
    stop: handleStop,
    answer: text,
    loading,
    error,
  }
}

// biome-ignore lint/style/noDefaultExport: ...
export default useChatGpt
