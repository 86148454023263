import { useCallback, useEffect, useRef, useState } from "react"

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react"

import type { Product } from "rikeimatch-graphql"

type Props = {
  products?: Omit<Product, "company">[]
}

const ProductList = ({ products }: Props) => {
  const [selectedProduct, setSelectedProduct] = useState<Omit<Product, "company"> | undefined>()
  const [bioTranslateX, setBioTranslateX] = useState(0)

  const ref = useRef<HTMLDivElement>(null)
  const bioWidth = ref.current?.offsetWidth

  // 紹介文の表示領域を調整する
  const refreshBioRef = useCallback(() => {
    if (!selectedProduct) {
      return
    }
    const bioElement = document.getElementsByClassName(selectedProduct.id)[0] as HTMLElement | null
    setBioTranslateX((bioElement?.offsetLeft || 0) - (ref.current?.offsetLeft || 0))
  }, [selectedProduct])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    refreshBioRef()
  }, [selectedProduct, refreshBioRef])

  if (!products) {
    return null
  }

  return (
    <Stack
      direction="row"
      sx={{
        mt: 3,
        mb: 40,
        flexWrap: "wrap",
        justifyContent: "flex-start",
        maxWidth: "1000px",
        width: "100%",
      }}
      ref={ref}
    >
      <Accordion
        allowToggle
        sx={{
          display: "flex",
          flexWrap: "wrap",
          ".chakra-collapse": { overflow: "visible !important" },
        }}
      >
        {products.map((product) => (
          <AccordionItem
            key={product.id}
            sx={{ minW: 200, maxW: 180, border: "none" }}
            onClick={() => setSelectedProduct(product)}
          >
            <AccordionButton>
              <Box
                sx={{
                  borderBottom:
                    // 変数で色指定
                    selectedProduct?.id === product.id ? "3px solid #2196f3" : "3px solid #FFFFFF",
                  paddingBottom: 3,
                }}
              >
                {product?.image ? (
                  <img
                    src={product?.image || ""}
                    alt={product.name}
                    loading="lazy"
                    width={180}
                    height={180}
                  />
                ) : (
                  <Box
                    width={180}
                    height={180}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#D3D3D3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <title>{product.name}</title>
                      <rect x="2" y="3" width="20" height="14" rx="2" ry="2" />
                      <line x1="8" y1="21" x2="16" y2="21" />
                      <line x1="12" y1="17" x2="12" y2="21" />
                    </svg>
                  </Box>
                )}
                <Text variant="body1" sx={{ textAlign: "center", width: "100%", mt: 1 }}>
                  {product.name}
                </Text>
                <Text
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    color: "gray.500",
                  }}
                />
              </Box>
            </AccordionButton>
            <AccordionPanel
              className={product.id}
              pb={4}
              sx={{
                transform: `translateX(-${bioTranslateX}px)`,
                width: bioWidth,
                transitionDuration: "500ms",
                py: 10,
              }}
            >
              <Box sx={{ alignItems: "center", display: "flex" }}>
                <Heading size="md">{product.name}</Heading>
              </Box>

              <Text
                sx={{
                  textAlign: "left",
                  whiteSpace: "pre-wrap",
                  my: 2,
                  wordBreak: "break-word",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  fontSize: "md",
                  pr: "10px",
                }}
              >
                {product.description}
              </Text>

              <Link href={`/products/${product.id}`} sx={{ display: "flex", alignItems: "center" }}>
                詳細へ
              </Link>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Stack>
  )
}

ProductList.defaultProps = {
  products: undefined,
}

// biome-ignore lint/style/noDefaultExport: ...
export default ProductList
