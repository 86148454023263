import type React from "react"
import { useForm } from "react-hook-form"
import type { SubmitHandler } from "react-hook-form"
import toast from "react-hot-toast"
import { useLocation, useNavigate } from "react-router-dom"

import { parse } from "query-string"

import { Box, Button, Checkbox, Flex, Input, Link, Text } from "@chakra-ui/react"
import { Auth, Hub } from "aws-amplify"
import { AUTH_STATE_CHANGE_EVENT, AuthState, Translations, UI_AUTH_CHANNEL } from "common-utils"

import { InputLabel, RequiredLabel } from "~/components/atoms"
import SectionHeader from "~/components/molecules/SectionHeader"
import { appHost } from "~/config"
import { useUser } from "~/containers"
import { useJobSeekerMeQuery, useJobSeekerUpdateProfileMutation } from "~/generated/graphql"

import LineLogin from "../molecules/LineLogin"

import styles from "./AuthForm.module.css"

type SignUpFormInput = {
  firstName: string
  lastName: string
  email: string
  password: string
  agreedToTos: boolean
}

export type Props = {
  afterComplete: () => void
  showSignInForm: () => void
}

const SignUpForm: React.FC<Props> = ({ afterComplete, showSignInForm }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { loading } = useJobSeekerMeQuery()
  const { code } = parse(location.search.replace("?", ""))
  const [updateSeekerProfile] = useJobSeekerUpdateProfileMutation()
  const { login } = useUser()

  const {
    handleSubmit,
    register,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<SignUpFormInput>({
    mode: "onBlur",
  })

  const handleSignUpSuccess = async () => {
    if (!loading) {
      afterComplete()
      if (code) {
        await updateSeekerProfile({
          variables: {
            input: {
              introducedById: Array.isArray(code) ? code.at(0) : code,
            },
          },
        })
      }
      navigate("/profile/new")
    }
  }

  const onSubmit: SubmitHandler<SignUpFormInput> = async (input) => {
    try {
      const params = {
        username: input.email,
        password: input.password,
        attributes: {
          given_name: input.firstName,
          family_name: input.lastName,
          // 電話番号を受け付けていないので固定する
          phone_number: "+810000000000",
        },
      }
      const data = await Auth.signUp(params)

      if (!data) {
        throw new Error(Translations.SIGN_UP_FAILED)
      }
      if (data.userConfirmed) {
        await login(params.username, params.password)
        void handleSignUpSuccess()
      } else {
        const signUpAttrs = { ...params }
        Hub.dispatch(UI_AUTH_CHANNEL, {
          event: AUTH_STATE_CHANGE_EVENT,
          message: AuthState.ConfirmSignUp,
          data: signUpAttrs,
        })
      }
    } catch (e) {
      const errorCode = typeof e === "object" ? (e as Record<string, string>).code : e
      if (errorCode === "UsernameExistsException") {
        setError("email", { message: "このメールアドレスは使用できません" }, { shouldFocus: true })
      }
      toast.error("ユーザー登録に失敗しました")
    }
  }

  return (
    <>
      <SectionHeader title="ユーザー登録" subtitle="entry" />
      <Box maxW="500px" margin="auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box py="15px">
            <RequiredLabel />
            <InputLabel text="メールアドレス" />
            <Input
              autoComplete="username"
              className={styles.txt}
              type="email"
              required
              isInvalid={!!errors.email}
              {...register("email", { required: true })}
            />
            <Text color="#b8526b">
              {errors.email?.type !== "required" && errors.email?.message}
            </Text>
          </Box>
          <Box py="15px">
            <RequiredLabel />
            <InputLabel text="パスワード" />
            <Input
              autoComplete="new-password"
              className={styles.txt}
              type="password"
              minLength={8}
              required
              isInvalid={!!errors.password}
              {...register("password", { required: true })}
            />
          </Box>
          <Box py="15px">
            <RequiredLabel />
            <InputLabel text="姓" />
            <Input
              className={styles.txt}
              type="text"
              autoComplete="family-name"
              required
              isInvalid={!!errors.lastName}
              {...register("lastName", { required: true })}
            />
          </Box>
          <Box py="15px">
            <RequiredLabel />
            <InputLabel text="名" />
            <Input
              className={styles.txt}
              type="text"
              autoComplete="given-name"
              required
              isInvalid={!!errors.firstName}
              {...register("firstName", { required: true })}
            />
          </Box>
          <Box textAlign="center">
            <Link isExternal href={`${appHost}/tos.html`}>
              利用規約
            </Link>
            、
            <Link isExternal href={`${appHost}/privacy.html`}>
              プライバシーポリシー
            </Link>
            をご確認ください。
            <Checkbox required {...register("agreedToTos", { required: true })}>
              上記承認事項に同意する
            </Checkbox>
          </Box>
          <Flex alignItems="center" direction="column" py="30px">
            <Button
              type="submit"
              isLoading={isSubmitting}
              w="240px"
              h="auto"
              bg="#0a94cf"
              color="white"
              fontWeight="normal"
              fontSize="1.3rem"
              padding="0.6em 0"
              _hover={{ bg: "#cf1359" }}
            >
              ユーザーを登録する
            </Button>
            <Text
              w="100%"
              textAlign="center"
              borderBottom="2px solid gray"
              lineHeight="0.1em"
              margin="30px 0 20px"
            >
              <Text as="span" fontSize="sm" fontWeight="normal" padding="0 10px" bg="white">
                または
              </Text>
            </Text>
            <LineLogin />
            <Text py="20px">
              <Button variant="link" textColor="#0a94cf" onClick={() => showSignInForm()}>
                新規登録済みの方はこちら
              </Button>
            </Text>
          </Flex>
        </form>
      </Box>
    </>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default SignUpForm
