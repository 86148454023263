import type React from "react"
import { useState } from "react"

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react"

import AuthForm from "./AuthForm"

import type { AuthState } from "common-utils"

const SignInModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  defaultAuthState: AuthState.SignIn | AuthState.SignUp
  handleAfterSignIn?: () => void
}> = (props) => {
  const [hasFinishedLogin, setHasFinishedLogin] = useState(false)
  const { isOpen, onClose, defaultAuthState, handleAfterSignIn } = props

  return (
    <Modal isOpen={!hasFinishedLogin && isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody borderRadius="5px">
          <ModalCloseButton zIndex={2} />
          <Box height="2rem" />
          <AuthForm
            initialState={defaultAuthState}
            afterComplete={() => {
              if (handleAfterSignIn) {
                handleAfterSignIn()
              }
              setHasFinishedLogin(true)
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default SignInModal
