import { Button, Heading, Image, Link, Stack, Text } from "@chakra-ui/react"

import { appHost } from "~/config"

import thanksForReferralComment from "./thanks-for-referral-comment.svg"

const ThanksPage = () => (
  <Stack spacing={10}>
    <Text>紹介文が送信されました。ご記入いただきありがとうございます！</Text>
    <Stack sx={{ justifyContent: "center" }} spacing={0}>
      <Heading size="sm">あなたもリケリンクを始めてみませんか？</Heading>
      <Text>
        リケリンクは理系学生向けの就活サイト「リケイマッチ」に登録することで利用できるサービスです。学びをストックしたり、就活用のポートフォリオとしても使うことができます！
      </Text>
      <Image src={thanksForReferralComment} width="100%" maxH="300px" alt="" py="20px" mx="auto" />
      <Link isExternal href={appHost} sx={{ width: "100%" }}>
        <Button colorScheme="blue" sx={{ w: "100%" }}>
          リケイマッチに登録する
        </Button>
      </Link>
    </Stack>
  </Stack>
)

// biome-ignore lint/style/noDefaultExport: ...
export default ThanksPage
