import type { FC } from "react"
import { Toaster } from "react-hot-toast"
import { BrowserRouter } from "react-router-dom"

import { ApolloProvider } from "@apollo/client"
import { NextUIProvider } from "@nextui-org/react"
import * as Sentry from "@sentry/react"
import { Amplify, I18n } from "aws-amplify"
import { amplifyMessages } from "common-utils"

import apolloClient from "~/apolloClient"
import awsConfig from "~/awsConfig"
import { sentryDsn } from "~/config"
import { UserProvider } from "~/containers"
import UiProvider from "~/containers/UiProvider"
import Routes from "~/routes"
import "~/lib/i18n"

Sentry.init({
  dsn: sentryDsn,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0.1,
  release: process.env.VITE_REVISION,
  environment: process.env.VITE_ENVIRONMENT,
})
Sentry.setTag("application", "job-seeker-client")

Amplify.configure(awsConfig)
I18n.putVocabularies(amplifyMessages)
I18n.setLanguage("ja")

const App: FC = () => (
  <>
    <Toaster />
    <UserProvider>
      <ApolloProvider client={apolloClient}>
        <UiProvider>
          <NextUIProvider>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </NextUIProvider>
        </UiProvider>
      </ApolloProvider>
    </UserProvider>
  </>
)

// biome-ignore lint/style/noDefaultExport: ...
export default App
