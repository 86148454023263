import { Flex } from "@chakra-ui/react"
import { Auth } from "aws-amplify"

import LineButton from "./LineButton"

const LineLogin = () => (
  <Flex justifyContent="center" mt={5} mb={10}>
    <LineButton
      type="button"
      w="240px"
      h="50px"
      onClick={() => {
        void Auth.federatedSignIn({ customProvider: "LINE" })
      }}
    >
      LINEでログイン
    </LineButton>
  </Flex>
)

// biome-ignore lint/style/noDefaultExport: ...
export default LineLogin
