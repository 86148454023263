import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { BsLightbulb } from "react-icons/bs"

type Props = {
  open: boolean
  onClick: () => void
  onClose: () => void
  disabled?: boolean
  text: string
  loading?: boolean
  error?: string
  retry?: () => void
}

const ImprovementProposalPopover = ({
  open,
  onClick,
  onClose,
  disabled,
  text,
  loading,
  error,
  retry,
}: Props) => (
  <Box
    sx={{ outline: "none" }}
    onBlur={(e) => {
      e.preventDefault()
      e.stopPropagation()
    }}
  >
    <Popover placement="top" closeOnBlur={false} isOpen={open} onClose={onClose}>
      <Tooltip label="100文字以上入力する必要があります。" isDisabled={!disabled}>
        <Box>
          {/* Box で囲わないと使えなくなる */}
          <PopoverTrigger>
            <Button
              leftIcon={<BsLightbulb />}
              variant="ghost"
              colorScheme="yellow"
              size="sm"
              onClick={onClick}
              isDisabled={disabled}
            >
              改善案を見る
            </Button>
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <Box
        sx={{
          "& .chakra-popover__popper": {
            zIndex: "popover",
          },
        }}
      >
        <PopoverContent
          sx={{
            outline: 0,
            maxW: { base: "300", md: "500" },
            minW: { base: "200", md: "400" },
            zIndex: 1000000,
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            WebkitTapHighlightColor: "red !important",
            bgColor: "#FFFFEE",
          }}
          tabIndex={undefined}
        >
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>AIによる改善案</PopoverHeader>
          {error && (
            <PopoverBody sx={{ textAlign: "center", my: 5 }}>
              エラーが発生しました。再度実行してください。
              {retry && (
                <Button onClick={() => retry()} sx={{ mt: 4 }}>
                  再実行
                </Button>
              )}
            </PopoverBody>
          )}
          {!loading && !error && (
            <PopoverBody
              sx={{
                whiteSpace: "pre-wrap",
                overflow: "auto",
                maxHeight: 500,
                minHeight: 100,
                py: 8,
                fontSize: "xs",
              }}
            >
              {(text || "").trim()}
            </PopoverBody>
          )}
          {loading && !error && (
            <PopoverBody sx={{ textAlign: "center" }}>
              <Spinner sx={{ my: 10 }} />
              <Text fontSize="xs">10秒以上かかる場合があります。しばらくお待ちください。</Text>
            </PopoverBody>
          )}
        </PopoverContent>
      </Box>
    </Popover>
  </Box>
)

ImprovementProposalPopover.defaultProps = {
  disabled: false,
  loading: false,
}

// biome-ignore lint/style/noDefaultExport: ...
export default ImprovementProposalPopover
