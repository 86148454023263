import { useMemo, useState } from "react"

import { Stack } from "@chakra-ui/react"
import { ImprovementProposalPopover } from "common-components-chakra"
import debounce from "lodash.debounce"
import { ChatGptMessageRole } from "rikeimatch-graphql"

import useChatGpt from "~/hooks/useChatGpt"

import FormTooltip from "./FormTooltip"

type Props = {
  text: string
  disabled?: boolean
  tooltip?: string
}

const ImprovementProposal = ({ text, disabled, tooltip }: Props) => {
  const [open, setOpen] = useState(false)

  const { sendMessage, stop, answer, error } = useChatGpt()

  const handleChange = useMemo(
    () =>
      debounce(() => {
        sendMessage([{ role: ChatGptMessageRole.User, content: text }])
      }, 1000),
    [sendMessage, text],
  )

  return (
    <Stack direction="row" spacing={1}>
      <ImprovementProposalPopover
        open={open}
        onClick={() => {
          setOpen(!open)
          if (text.length >= 100) {
            void handleChange()
          }
        }}
        onClose={() => {
          setOpen(false)
          stop()
        }}
        text={answer || ""}
        disabled={disabled}
        error={error}
        retry={() => {
          stop()
          void handleChange()
        }}
      />
      <FormTooltip>{tooltip}</FormTooltip>
    </Stack>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default ImprovementProposal
