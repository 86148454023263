import { format, formatISO, parseISO } from "date-fns"
import ja from "date-fns/locale/ja"

export const formatDatetime = (datetime: string, withTime = true): string =>
  new Intl.DateTimeFormat("ja-JP", {
    dateStyle: "medium",
    timeStyle: withTime ? "short" : undefined,
    // Safari対応 https://github.com/w3c/respec/issues/1357#issuecomment-760913749
  }).format(new Date(datetime.replace(/-/g, "/")))

export const parseISODateString = (dateString: string | Date) =>
  typeof dateString === "string" ? parseISO(dateString) : dateString

export const formatISODatetime = (datetime: string): string => {
  const dateObject = parseISO(datetime)
  return format(dateObject, "yyyy/MM/dd H:mm")
}

export const formatISODate = (datetime: string): string => {
  const dateObject = parseISO(datetime)
  return format(dateObject, "yyyy/MM/dd")
}

export const ISODateTimeToDateString = (
  dateTimeString: null | undefined | string | Date,
  withoutDay = false,
) => {
  if (!dateTimeString) {
    return undefined
  }
  const dateTime = parseISODateString(dateTimeString)

  return withoutDay ? format(dateTime, "yyyy-MM") : formatISO(dateTime, { representation: "date" })
}

export const formatDateWithDay = (datetime: string, withTime = false): string => {
  const date = parseISO(datetime)
  const formatString = withTime ? "yyyy年MM月dd日(eee) HH:mm" : "yyyy年MM月dd日(eee)"

  return format(date, formatString, { locale: ja })
}
