import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react"
import { FEEDBACK_PROMPT, domainMessages } from "common-utils"
import { ChatGptMessageRole, ChatGptThreadKind } from "rikeimatch-graphql"

import InterviewHistories from "~/components/organisms/InterviewHistories"
import InterviewHistoryThread from "~/components/organisms/InterviewHistoryThread"
import InterviewInput from "~/components/organisms/InterviewInput"
import InterviewMessages from "~/components/organisms/InterviewMessages"
import InterviewVideoAvatar from "~/components/organisms/InterviewVideoAvatar"
import type { JobSeekerChatGptThreadsQuery, JobSeekerMeQuery } from "~/generated/graphql"
import {
  useJobSeekerAiInterviewAvailabilityQuery,
  useJobSeekerAiInterviewHistoriesQuery,
  useJobSeekerMeQuery,
} from "~/generated/graphql"
import useChatConversation from "~/hooks/useChatConversation"

import type { ChatGptMessageInputSource, InterviewVersion } from "rikeimatch-graphql"

const MAX_ANSWERS = 5

const buildJobSeekerInfo = (jobSeeker: JobSeekerMeQuery["jobSeekerMe"]) => {
  if (!jobSeeker || !jobSeeker.educationHistory) {
    return ""
  }

  const { focusSummary, focusDetail } = jobSeeker
  const { studyKeywords = [], studyDetail } = jobSeeker

  const { researchSubject, researchDetail } = jobSeeker.educationHistory

  const intro = "私のプロフィールです。"
  const study = `学習内容: ${studyKeywords.join(", ")}\n${studyDetail}`
  const research = `研究内容: \n${researchSubject || ""} ${researchDetail || ""}`
  const focus = `学生時代に力を入れたこと: \n${focusSummary || ""} ${focusDetail || ""}`

  return `${intro}\n\n${study}\n\n${research}\n\n${focus}`
}

const checkProfile = (jobSeeker: JobSeekerMeQuery["jobSeekerMe"]) => {
  if (!jobSeeker) {
    return { isReady: false, profileErrors: [] }
  }

  const { educationHistory } = jobSeeker

  const errors = []
  if (!educationHistory) {
    errors.push(`${domainMessages.resources.educationHistory.name}が入力されていません。`)
  }
  if (!jobSeeker?.focusSummary) {
    errors.push(`${domainMessages.resources.jobSeekers.fields.focusSummary}が入力されていません。`)
  }
  if (!jobSeeker?.focusDetail) {
    errors.push(`${domainMessages.resources.jobSeekers.fields.focusDetail}が入力されていません。`)
  }
  if (!(jobSeeker?.studyKeywords.length > 0)) {
    errors.push(`${domainMessages.resources.jobSeekers.fields.studyKeywords}が入力されていません。`)
  }
  if (!jobSeeker?.studyDetail) {
    errors.push(`${domainMessages.resources.jobSeekers.fields.studyDetail}が入力されていません。`)
  }
  /* if (!educationHistory?.researchSubject)
    errors.push(
      `${domainMessages.resources.educationHistory.fields.researchSubject}が入力されていません。`,
    )
  if (!educationHistory?.researchDetail)
    errors.push(
      `${domainMessages.resources.educationHistory.fields.researchDetail}が入力されていません。`,
    ) */

  return {
    isReady: errors.length === 0,
    profileErrors: errors,
  }
}

type ThreadType = JobSeekerChatGptThreadsQuery["chatGptThreads"][0]

type PropsType = {
  version: InterviewVersion
}

const Interview = ({ version }: PropsType) => {
  const [aiMessage, setAiMessage] = useState<string | undefined>(undefined)
  const [isAiVideoShown, setIsAiVideoShown] = useState(false)
  const [selectedThread, setSelectedThread] = useState<ThreadType | undefined>(undefined)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobile = useBreakpointValue({ base: true, md: false })

  const { startConversation, sendUserMessage, loading, answer, messages, hasStarted, retry } =
    useChatConversation({
      version,
      onFinished: (finalOutput) => {
        setAiMessage(finalOutput)
      },
    })

  const { data: availabilityData } = useJobSeekerAiInterviewAvailabilityQuery()
  const availability = availabilityData?.aiInterviewAvailability
  const isAvailable = (availability?.availableInterviewsCount || 0) > 0

  const { data } = useJobSeekerMeQuery()
  const jobSeeker = data?.jobSeekerMe

  const { data: interviewHistoriesData } = useJobSeekerAiInterviewHistoriesQuery()
  const interviewHistories = interviewHistoriesData?.aiInterviewHistories

  const { isReady, profileErrors } = checkProfile(jobSeeker)

  const uniqueUserContents = new Set(
    messages.filter((m) => m.role === ChatGptMessageRole.User).map((m) => m.content),
  )
  const hasFinished = uniqueUserContents.size > MAX_ANSWERS

  const handleSubmit = (input: string, inputSource: ChatGptMessageInputSource) => {
    void sendUserMessage(input, inputSource)
  }

  const isPublished = interviewHistories?.some(
    (history) => history.chatGptThread.id === selectedThread?.id,
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (hasFinished) {
      void sendUserMessage(FEEDBACK_PROMPT)
    }
  }, [hasFinished])

  return (
    <Stack
      sx={{
        h: "calc(100vh - 64px)",
        pt: 4,
        pb: 8,
        px: 4,
        mx: "auto",
        position: "relative",
      }}
      direction={{ base: "column", md: "row" }}
      spacing={10}
    >
      {isMobile && (
        <Stack sx={{ position: "absolute", top: 4, right: 4, zIndex: 10 }}>
          <Button variant="solid" onClick={onOpen}>
            履歴
          </Button>
        </Stack>
      )}
      {isMobile ? (
        <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px">面接診断</DrawerHeader>
            <DrawerBody>
              <Stack
                spacing={4}
                sx={{
                  borderRight: "1px solid",
                  borderColor: "gray.200",
                  px: 4,
                  py: 2,
                  flexDirection: "column",
                  overflow: "hidden",
                  minW: 56,
                  h: "100%",
                }}
              >
                <Stack>
                  <Button onClick={() => setSelectedThread(undefined)}>新しい面接を開始</Button>
                </Stack>
                <Divider />
                <InterviewHistories
                  selectedThread={selectedThread}
                  handleSelectThread={(thread) => setSelectedThread(thread)}
                  interviewHistories={interviewHistories || []}
                />
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      ) : (
        <Stack
          spacing={4}
          sx={{
            borderRight: "1px solid",
            borderColor: "gray.200",
            px: 4,
            py: 2,
            flexDirection: "column",
            overflow: "hidden",
            minW: 56,
          }}
        >
          <Stack>
            <Button onClick={() => setSelectedThread(undefined)}>新しい面接を開始</Button>
          </Stack>
          <Divider />
          <InterviewHistories
            selectedThread={selectedThread}
            handleSelectThread={(thread) => setSelectedThread(thread)}
            interviewHistories={interviewHistories || []}
          />
        </Stack>
      )}
      {selectedThread && (
        <Stack sx={{ overflow: "auto" }}>
          <InterviewHistoryThread thread={selectedThread} isPublished={isPublished} />
        </Stack>
      )}
      <Stack
        sx={{
          w: { base: "full", md: "xl" },
          display: selectedThread ? "none" : "flex",
          h: "100%",
          mx: "auto !important",
        }}
      >
        {!hasStarted && (
          <Stack sx={{ flexGrow: 1, justifyContent: "center", mx: "auto" }} spacing={4}>
            <Stack>
              <Text fontSize={{ base: "x-large", md: "large" }}>
                AI面接官があなたのプロフィールに基づいて模擬面接を行います。
              </Text>
              <Text fontSize={{ base: "x-large", md: "large" }}>
                質問は合計5回行い、最後にフィードバックが表示されます。
              </Text>
              <Text fontSize={{ base: "x-large", md: "large" }}>
                {" "}
                AI面接診断は1日2回まで使用することができます。
              </Text>
            </Stack>
            <Button
              onClick={async () => {
                await startConversation({
                  kind: ChatGptThreadKind.InterviewPractice,
                  firstMessage: buildJobSeekerInfo(jobSeeker),
                })
                const footer = document.getElementById("footer")
                if (footer) {
                  footer.style.display = "none"
                }
              }}
              isDisabled={!isReady || !isAvailable}
            >
              開始する
            </Button>
            {/* 開発環境でのみ一旦表示 */}
            {process.env.NODE_ENV === "development" && (
              <Button onClick={() => setIsAiVideoShown(!isAiVideoShown)}>アバターを表示</Button>
            )}
            {!isAvailable && <Text>1日の使用回数を超えました。明日またお試しください。</Text>}
            {!isReady && (
              <Stack>
                <Text>
                  AI面接診断
                  を使用するには、プロフィール設定が完了している必要があります。下記の項目を入力してください。
                </Text>
                <Stack sx={{ bgColor: "blue.100", p: 2 }}>
                  {profileErrors.map((error) => (
                    <Text key={error}>{error}</Text>
                  ))}
                </Stack>
                <Link to="/profile">
                  <Button>プロフィール設定へ</Button>
                </Link>
              </Stack>
            )}
          </Stack>
        )}
        {hasStarted && (
          <InterviewMessages messages={messages} streamingAnswer={loading ? answer : undefined} />
        )}
        {hasStarted && (
          <InterviewInput
            handleSubmit={handleSubmit}
            handleRetry={retry}
            hasFinished={hasFinished}
            loading={loading}
          />
        )}
        {hasFinished && !loading && (
          <Stack sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", my: 2 }}>
            <Button onClick={() => window.location.reload()}>終了し、最初の画面へ戻る</Button>
          </Stack>
        )}
      </Stack>
      {/* 開発環境でのみ一旦表示、テスト ver */}
      {isAiVideoShown && process.env.NODE_ENV === "development" && (
        <Stack sx={{ my: "auto" }}>
          {hasStarted && uniqueUserContents.size > 0 && (
            <InterviewVideoAvatar aiMessage={aiMessage} />
          )}
        </Stack>
      )}
    </Stack>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default Interview
