import { useState } from "react"

import { SYSTEM_PROMPT_V1, SYSTEM_PROMPT_V3 } from "common-utils"
import { ChatGptMessageRole, InterviewVersion } from "rikeimatch-graphql"

import {
  useJobSeekerChatGptThreadQuery,
  useJobSeekerCreateChatGptThreadMutation,
  useJobSeekerCreateJobInterviewMutation,
  useJobSeekerStoreChatGptMessagesMutation,
} from "~/generated/graphql"

import useChatGpt from "./useChatGpt"

import type { ChatGptMessageInputSource, CreateChatGptThreadInput } from "rikeimatch-graphql"

type StartConversationProps = Omit<CreateChatGptThreadInput, "systemContent" | "version"> & {
  firstMessage: string
}

type Props = {
  version?: InterviewVersion
  onFinished?: (finalOutput: string, threadId?: string) => void
}

const useChatConversation = ({ version, onFinished } = {} as Props) => {
  const [createChatGptThread] = useJobSeekerCreateChatGptThreadMutation()
  const [storeChatGptMessages] = useJobSeekerStoreChatGptMessagesMutation()
  const [createJobInterview, { data: jobInterviewData }] = useJobSeekerCreateJobInterviewMutation()
  const jobInterview = jobInterviewData?.createJobInterview

  const [threadId, setThreadId] = useState<string | undefined>(undefined)

  const { data, refetch } = useJobSeekerChatGptThreadQuery({
    variables: {
      id: threadId || "",
    },
    skip: !threadId,
  })
  const thread = data?.chatGptThread
  const messages = thread?.messages || []
  const lastMessage = messages[messages.length - 1]

  const { answer, sendMessage, stop, error, loading } = useChatGpt({
    onFinished: async (finalOutput: string, threadId?: string) => {
      if (!threadId) {
        return
      }
      if (onFinished) {
        onFinished(finalOutput, threadId)
      }
      await storeChatGptMessages({
        variables: {
          input: {
            threadId,
            messages: [
              {
                content: finalOutput,
                role: ChatGptMessageRole.Assistant,
              },
            ],
          },
        },
        onCompleted: () => {
          void refetch()
        },
      })
    },
  })

  const sendUserMessage = async (message: string, inputSource?: ChatGptMessageInputSource) => {
    sendMessage(
      [
        {
          role: ChatGptMessageRole.User,
          content: message,
        },
      ],
      threadId,
    )
    await storeChatGptMessages({
      variables: {
        input: {
          threadId,
          messages: [
            {
              role: ChatGptMessageRole.User,
              content: message,
              inputSource,
            },
          ],
        },
      },
      onCompleted: () => {
        void refetch()
      },
    })
  }

  const startConversation = async ({ kind, firstMessage }: StartConversationProps) => {
    let systemContent = SYSTEM_PROMPT_V1
    if (version === InterviewVersion.V3) {
      systemContent = SYSTEM_PROMPT_V3
    }

    await createChatGptThread({
      variables: {
        input: {
          kind,
          systemContent, // TODO: Backend に移行した方が良い
          version: version || InterviewVersion.V2,
        },
      },
      onCompleted: async (data) => {
        const newThreadId = data.createChatGptThread?.id
        setThreadId(newThreadId)
        sendMessage(
          [
            {
              role: ChatGptMessageRole.User,
              content: firstMessage,
            },
          ],
          newThreadId,
        )
        await storeChatGptMessages({
          variables: {
            input: {
              threadId: newThreadId,
              messages: [
                {
                  role: ChatGptMessageRole.User,
                  content: firstMessage,
                },
              ],
            },
          },
        })
      },
    })
  }

  const startJobInterview = async ({ jobId }: { jobId: string }) => {
    await createJobInterview({
      variables: {
        input: {
          jobId,
        },
      },
      onCompleted: (data) => {
        const newThreadId = data.createJobInterview?.chatGptThreadId
        if (!newThreadId) {
          return
        }

        setThreadId(newThreadId)
        sendMessage([], newThreadId)
      },
    })
  }

  const retry = () => {
    if (!lastMessage) {
      return
    }
    void sendUserMessage(lastMessage.content)
  }

  const messagesWithoutSystem = messages.filter(
    (message) => message.role !== ChatGptMessageRole.System,
  )
  const hasStarted = messagesWithoutSystem.length > 0 || loading

  return {
    startConversation,
    startJobInterview,
    sendUserMessage,
    loading,
    stop,
    error,
    answer,
    messages: messagesWithoutSystem,
    hasStarted,
    retry,
    jobInterview,
  }
}

// biome-ignore lint/style/noDefaultExport: ...
export default useChatConversation
