import type React from "react"
import type { PropsWithChildren, ReactNode } from "react"

import { Flex, FormLabel, Stack } from "@chakra-ui/react"

import FormControl from "./FormControl"

const InputContainer: React.FC<
  PropsWithChildren<{
    label: string | ReactNode
    name: string
    invalid: boolean
    direction?: "row" | "column"
    tooltip?: ReactNode
  }>
> = (props) => (
  <FormControl isInvalid={props.invalid}>
    <Stack direction={props.direction} display={{ base: "inherit", md: "flex" }} spacing={0}>
      <FormLabel htmlFor={props.name} fontSize="sm" fontWeight="md" sx={{ mr: 0 }}>
        <Flex sx={{ display: "flex", alignItems: "center" }}>
          {props.label}
          {props.tooltip}
        </Flex>
      </FormLabel>
      {props.children}
    </Stack>
  </FormControl>
)

InputContainer.defaultProps = {
  direction: "column",
  tooltip: undefined,
}

// biome-ignore lint/style/noDefaultExport: ...
export default InputContainer
