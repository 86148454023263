import type React from "react"
import type { SingleValue } from "react-select"

import { Skeleton } from "@chakra-ui/react"
import CreatableSelect from "react-select/creatable"

import { useJobSeekerMeQuery } from "~/generated/graphql"

const majors = ["機械", "電気電子", "情報", "数物科学", "化学", "生物", "農学", "建築"].map(
  (label) => ({
    label,
    value: label,
  }),
)

const EducationMajorSelect: React.FC<{
  onChange: (value: string) => void
}> = ({ onChange }) => {
  const { data, loading } = useJobSeekerMeQuery()
  const defaultValue = () => {
    if (!loading) {
      const major = data?.jobSeekerMe?.educationHistory?.major

      return major ? { label: major, value: major } : undefined
    }

    return []
  }
  const handleChange = (newValue: SingleValue<{ label: string; value: string }>) => {
    const major = newValue?.label
    if (major) {
      onChange(major)
    }
  }

  return loading ? (
    <Skeleton height="20px" />
  ) : (
    <CreatableSelect
      onChange={handleChange}
      defaultValue={defaultValue()}
      options={majors}
      formatCreateLabel={(newLabel: string) => `${newLabel}を追加する`}
      noOptionsMessage={() => "選択肢がありません"}
    />
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default EducationMajorSelect
