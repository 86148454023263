import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { Auth } from "aws-amplify"

import { apiHost } from "./config"

import type { DefaultOptions } from "@apollo/client"

type RequestHeaders = {
  headers: {
    authorization: string
    [key: string]: string
  }
}

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
}

const authLink = setContext(async (_, { headers }): Promise<RequestHeaders> => {
  try {
    const session = await Auth.currentSession()
    const token = session.getAccessToken().getJwtToken()

    return {
      headers: {
        ...(headers as Record<string, string>),
        authorization: `Bearer ${token}`,
      },
    }
  } catch (error) {
    if (error !== "No current user") {
      console.error(error)
    }

    return { headers }
  }
})

const httpLink = createHttpLink({
  uri: `${apiHost}/graphql`,
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  name: "jobSeeker",
  version: process.env.VITE_REVISION,
  defaultOptions,
})

// biome-ignore lint/style/noDefaultExport: ...
export default client
