import type React from "react"

import { Box } from "@chakra-ui/react"

const RequiredLabel: React.FC = () => (
  <Box as="span" padding="1px 6px" mr="8px" color="white" bg="#b8526b">
    必須
  </Box>
)

// biome-ignore lint/style/noDefaultExport: ...
export default RequiredLabel
