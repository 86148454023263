import { useParams } from "react-router-dom"

import { Box, Flex, Heading, Image, Stack, Text } from "@chakra-ui/react"

import { useJobSeekerShowProductQuery } from "~/generated/graphql"

const ProductDetail = () => {
  const { id = "" } = useParams<{ id: string }>()
  const { loading, data } = useJobSeekerShowProductQuery({
    variables: { id },
    skip: !id,
  })
  const product = data?.product

  if (loading || !product) {
    return null
  }

  return (
    <Box sx={{ maxW: 800, my: 50, mx: "auto", px: 4 }}>
      <Flex sx={{ mb: 10 }}>
        {product?.image ? (
          <Image boxSize="180px" objectFit="cover" src={product?.image || ""} alt={product.name} />
        ) : (
          <Box width={180} height={180} display="flex" alignItems="center" justifyContent="center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#D3D3D3"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <title>{product.name}</title>
              <rect x="2" y="3" width="20" height="14" rx="2" ry="2" />
              <line x1="8" y1="21" x2="16" y2="21" />
              <line x1="12" y1="17" x2="12" y2="21" />
            </svg>
          </Box>
        )}
        <Stack spacing={1} sx={{ ml: { base: 5, md: 10 }, justifyContent: "center" }}>
          <Heading size="md">{product.name}</Heading>
        </Stack>
      </Flex>
      <Stack spacing={12}>
        {product.description && (
          <Box>
            <Heading size="md">製品紹介</Heading>
            <Text sx={{ whiteSpace: "pre-wrap", py: 2 }}>{product.description}</Text>
          </Box>
        )}
      </Stack>
    </Box>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default ProductDetail
