import type React from "react"
import { useForm } from "react-hook-form"
import type { SubmitHandler } from "react-hook-form"

import {
  Box,
  Flex,
  FormHelperText,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react"
import { ISODateTimeToDateString, i18n, objectToOptions, parseISODateString } from "common-utils"
import { endOfMonth } from "date-fns"

import { notifySaveFailure, notifySaveSuccess } from "~/components/atoms"
import {
  EducationMajorSelect,
  EducationalInstitutionSelect,
  FormControl,
  FormLabel,
  InputContainer,
  ProfileTemplateModal,
  StudyKeywordsSelect,
} from "~/components/molecules"
import { useJobSeekerMeQuery, useJobSeekerUpdateProfileMutation } from "~/generated/graphql"

import FormTooltip from "../molecules/FormTooltip"
import GenerateResearchDetailButton from "../molecules/GenerateResearchDetailButton"
import GenerateStudyDetailButton from "../molecules/GenerateStudyDetailButton"
import ImprovementProposal from "../molecules/ImprovementProposal"

import type { EducationHistoryGrade } from "rikeimatch-graphql"

const gradeOptions = objectToOptions(i18n.grades)

type EducationFormInput = {
  name: string
  grade: EducationHistoryGrade
  major: string
  faculty: string
  department: string
  researchSubject: string | undefined
  researchDetail: string | undefined
  studyKeywords: string | undefined
  studyDetail: string | undefined
  graduatedOn: string
  highSchool: string | undefined
}

const EducationHistoryForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<EducationFormInput>({
    mode: "onBlur",
    shouldFocusError: false,
  })
  const studyDetailValue = watch("studyDetail")
  const researchDetailValue = watch("researchDetail")

  const studyDetailCommand = `以下は就職活動におけるプロフィールの学習内容を記述したものです。文章内容の具体的な改善案を3つ提示してください。なお、3つの改善案を合わせて1400文字以内になるよう回答してください。\n\n${
    studyDetailValue || ""
  }`
  const researchDetailCommand = `以下は就職活動におけるプロフィールの研究内容を記述したものです。文章内容の具体的な改善案を3つ提示してください。なお、3つの改善案を合わせて1400文字以内になるよう回答してください。\n\n${
    researchDetailValue || ""
  }`

  useJobSeekerMeQuery({
    onCompleted: (data) => {
      if (data.jobSeekerMe) {
        const jobSeeker = {
          studyKeywords: data?.jobSeekerMe?.studyKeywords?.join() || undefined,
          studyDetail: data?.jobSeekerMe?.studyDetail,
        }
        const defaultValues = Object.fromEntries(
          Object.entries(data?.jobSeekerMe?.educationHistory || {}).filter(([key]) =>
            [
              "name",
              "grade",
              "major",
              "faculty",
              "department",
              "researchSubject",
              "researchDetail",
              "graduatedOn",
              "highSchool",
            ].includes(key),
          ),
        )

        reset({
          ...defaultValues,
          ...jobSeeker,
          graduatedOn: ISODateTimeToDateString(defaultValues.graduatedOn, true),
        })
      }
    },
  })
  const [updateSeekerProfile, { error }] = useJobSeekerUpdateProfileMutation()

  const cardBg = useColorModeValue("white", "gray.800")
  const canvasBg = useColorModeValue("white", "gray.700")
  const formLabelColor = useColorModeValue("gray.700", "gray.50")

  const onSubmit: SubmitHandler<EducationFormInput> = async (submittedData) => {
    const { studyKeywords, studyDetail, ...dataWithoutJobSeeker } = submittedData
    const graduatedOn = ISODateTimeToDateString(
      endOfMonth(parseISODateString(submittedData.graduatedOn)),
    )
    const educationHistory = graduatedOn
      ? { ...dataWithoutJobSeeker, graduatedOn }
      : dataWithoutJobSeeker
    await updateSeekerProfile({
      variables: {
        input: {
          studyKeywordsString: studyKeywords,
          studyDetail,
          educationHistory,
        },
      },
    })
    if (error) {
      notifySaveFailure()
    } else {
      notifySaveSuccess()
    }
  }

  return (
    <Box shadow="base" rounded={[null, "md"]} overflow={{ sm: "hidden" }}>
      <Stack px={4} py={5} bg={canvasBg} spacing={6} p={{ sm: 8 }}>
        <form onBlur={handleSubmit(onSubmit)}>
          <Box maxW="600px" w="full" bg={cardBg} rounded="md" overflow="hidden" borderStyle="solid">
            <InputContainer name="name" label="学校名" invalid={!!errors.name}>
              <EducationalInstitutionSelect
                onChange={(value) => {
                  setValue("name", value, { shouldDirty: true })
                }}
              />
            </InputContainer>

            <InputContainer name="grade" label="学年" invalid={!!errors.grade}>
              <Select
                w="200px"
                marginBottom="10px"
                placeholder="-"
                isInvalid={!!errors.grade}
                isRequired
                {...register("grade", { required: true })}
              >
                {gradeOptions.map((grade) => (
                  <option key={grade.id} value={grade.id}>
                    {grade.name}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer name="faculty" label="学部" invalid={!!errors.faculty}>
              <Input
                w="200px"
                marginBottom="10px"
                isInvalid={!!errors.faculty}
                isRequired
                {...register("faculty", { required: true })}
              />
            </InputContainer>

            <InputContainer name="department" label="学科" invalid={!!errors.department}>
              <Input
                w="200px"
                marginBottom="10px"
                isInvalid={!!errors.department}
                isRequired
                {...register("department", { required: true })}
              />
            </InputContainer>

            <InputContainer
              name="major"
              label="専攻分野（特に力を入れた分野を１つお選びください）"
              invalid={!!errors.major}
            >
              <EducationMajorSelect
                onChange={(value) => {
                  setValue("major", value, { shouldDirty: true })
                }}
              />
            </InputContainer>

            <InputContainer name="highSchool" label="卒業高校" invalid={!!errors.highSchool}>
              <Input
                w="200px"
                marginBottom="10px"
                isInvalid={!!errors.highSchool}
                {...register("highSchool")}
              />
            </InputContainer>

            <FormControl isInvalid={!!errors.studyKeywords}>
              <Stack display={{ base: "inherit", md: "flex" }} spacing={0}>
                <FormLabel fontSize="sm" fontWeight="md" sx={{ mr: 0 }}>
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Flex sx={{ display: "flex", alignItems: "center" }}>
                      学習(タグ) ※ 手動入力 or 選択式
                      <FormTooltip>
                        <Text>
                          学習（タグ）、学習内容（詳細）は企業がスカウトを送る上で特に重視している内容です。
                        </Text>
                        <Text>
                          履修科目、実験、研究、課外活動など通して学んだことを単語ベースで１つ１つ入力しましょう。
                        </Text>
                        <Text>※採用活動の裏側ですが、企業は単語で検索をする事も多いです。</Text>
                      </FormTooltip>
                    </Flex>
                    <GenerateStudyDetailButton
                      studyKeywords={watch("studyKeywords")}
                      setValue={(value: string) => setValue("studyDetail", value)}
                    />
                  </Stack>
                </FormLabel>
                <StudyKeywordsSelect
                  onChange={(value) => {
                    // setValueは第2引数に空の配列を指定すると値を更新しないのでCSV形式で扱う
                    setValue("studyKeywords", value.join(), {
                      shouldDirty: true,
                    })
                  }}
                />
              </Stack>
            </FormControl>

            <FormControl>
              <Stack direction="row" spacing={4} align="center" justify="space-between">
                <FormLabel fontSize="sm" fontWeight="md" paddingTop="10px" color={formLabelColor}>
                  <Flex>
                    <Text>学習内容(詳細)</Text>
                    <FormTooltip>
                      <Text>
                        学習タグにそって、具体的にどんな学習をしてきたのか記載しましょう。
                        実験・実習について記載、得意分野に絞って記載、自主学習について記載するなど書き方は自由です。
                      </Text>
                    </FormTooltip>
                  </Flex>
                </FormLabel>
                <Stack direction={{ base: "column", md: "row" }} spacing={0}>
                  <ProfileTemplateModal
                    kind="studyDetail"
                    originalText={studyDetailValue}
                    onChange={(text) => setValue("studyDetail", text)}
                  />
                  <ImprovementProposal
                    text={studyDetailCommand}
                    disabled={!studyDetailValue || studyDetailValue.length < 100}
                    tooltip="あなたの学んだ内容を、AIリケイサポーターが自動添削してフィードバックします。"
                  />
                </Stack>
              </Stack>
              <Textarea
                isInvalid={!!errors.studyDetail}
                {...register("studyDetail", { minLength: 50, maxLength: 800 })}
              />
              <FormHelperText marginTop="1px" marginBottom="10px">
                50~800文字
              </FormHelperText>
            </FormControl>

            <FormControl isInvalid={!!errors.researchSubject}>
              <Stack display={{ base: "inherit", md: "flex" }} spacing={0}>
                <FormLabel fontSize="sm" fontWeight="md" sx={{ mr: 0 }}>
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Flex sx={{ display: "flex", alignItems: "center" }}>研究テーマ</Flex>
                    <GenerateResearchDetailButton
                      value={watch("researchSubject")}
                      setValue={(value: string) => setValue("researchDetail", value)}
                    />
                  </Stack>
                </FormLabel>
                <Input
                  maxLength={50}
                  isInvalid={!!errors.researchSubject}
                  {...register("researchSubject")}
                />
                <FormHelperText marginTop="1px" marginBottom="10px">
                  ~50文字
                </FormHelperText>
              </Stack>
            </FormControl>

            <FormControl>
              <Stack direction="row" spacing={4} align="center" justify="space-between">
                <FormLabel fontSize="sm" fontWeight="md" color={formLabelColor}>
                  <Flex>
                    <Text>研究内容</Text>
                    <FormTooltip>
                      <Text>
                        研究課題、具体的な取り組み、成果・実績、研究を通して学んだことなど記載していきましょう。
                        研究が始まっていない（決まっていない）場合、研究で学びたいことや研究テーマを選ぶ上で大事にしたいことなど記載していきましょう。
                      </Text>
                      <Text>※ 研究が始まった場合は、随時新しい情報へ更新してください。</Text>
                    </FormTooltip>
                  </Flex>
                </FormLabel>

                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={0}
                  sx={{ justifyContent: "space-between" }}
                >
                  <ProfileTemplateModal
                    kind="researchDetail"
                    originalText={researchDetailValue}
                    onChange={(text) => setValue("researchDetail", text)}
                  />
                  <ImprovementProposal
                    text={researchDetailCommand}
                    disabled={!researchDetailValue || researchDetailValue.length < 100}
                    tooltip="あなたの研究内容を、AIリケイサポーターが自動添削してフィードバックします。"
                  />
                </Stack>
              </Stack>
              <Textarea
                isInvalid={!!errors.researchDetail}
                {...register("researchDetail", {
                  minLength: 50,
                  maxLength: 800,
                })}
              />
              <FormHelperText marginTop="1px" marginBottom="10px">
                50~800文字
              </FormHelperText>
            </FormControl>

            <InputContainer
              name="graduatedOn"
              label="卒業（見込み）"
              invalid={!!errors.graduatedOn}
            >
              <Input
                w="200px"
                marginBottom="10px"
                type="month"
                min="2018-03"
                max="2030-03"
                isInvalid={!!errors.graduatedOn}
                isRequired
                {...register("graduatedOn", { required: true })}
              />
            </InputContainer>
          </Box>
        </form>
      </Stack>
    </Box>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default EducationHistoryForm
