import type React from "react"
import { useEffect, useState } from "react"

import UnreadMessagesAlertDialog from "~/components/organisms/UnreadMessagesAlertDialog"
import { useJobSeekerMeQuery } from "~/generated/graphql"
import useUnreadItemsCount from "~/hooks/useUnreadItemsCount"

const UnreadMessagesProvider = ({ children }: { children: React.ReactNode }) => {
  const { hasUnreadJobLikes, hasUnreadMessages, hasUnreadScouts } = useUnreadItemsCount()

  const { data: jobSeekerData } = useJobSeekerMeQuery()
  const jobSeeker = jobSeekerData?.jobSeekerMe
  const doneSetup = !!jobSeeker?.educationHistory

  const [lastClosedDate, setLastClosedDate] = useState<number | null>(null)

  const handleCloseDialog = () => {
    const now = Date.now()
    localStorage.setItem("unreadDialogLastClosed", now.toString())
    setLastClosedDate(now)
  }

  useEffect(() => {
    // Load the last closed timestamp from localStorage
    const lastClosedTimestamp = localStorage.getItem("unreadDialogLastClosed")
    if (lastClosedTimestamp) {
      setLastClosedDate(Number.parseInt(lastClosedTimestamp, 10))
    }
  }, [])

  const twoWeeksPassedSinceLastSeen = Date.now() - (lastClosedDate ?? 0) > 1000 * 60 * 60 * 24 * 14
  const shouldShowDialog = doneSetup && twoWeeksPassedSinceLastSeen
  const showDialog = shouldShowDialog && (hasUnreadJobLikes || hasUnreadMessages || hasUnreadScouts)

  useEffect(() => {
    // テスト用に再表示できるコードを残しておく
    // localStorage.removeItem("unreadDialogLastClosed")
  }, [])

  return (
    <>
      {showDialog && <UnreadMessagesAlertDialog handleOnClose={handleCloseDialog} />}
      {children}
    </>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default UnreadMessagesProvider
