import { useCallback, useEffect, useRef, useState } from "react"

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Heading,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react"

import type { Member } from "rikeimatch-graphql"

type Props = {
  members?: Omit<Member, "company">[]
}

const MemberList = ({ members }: Props) => {
  const [selectedMember, setSelectedMember] = useState<Omit<Member, "company"> | undefined>()
  const [bioTranslateX, setBioTranslateX] = useState(0)

  const ref = useRef<HTMLDivElement>(null)
  const bioWidth = ref.current?.offsetWidth

  // 紹介文の表示領域を調整する
  const refreshBioRef = useCallback(() => {
    if (!selectedMember) {
      return
    }
    const bioElement = document.getElementsByClassName(selectedMember.id)[0] as HTMLElement | null
    setBioTranslateX((bioElement?.offsetLeft || 0) - (ref.current?.offsetLeft || 0))
  }, [selectedMember])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    refreshBioRef()
  }, [selectedMember, refreshBioRef])

  if (!members) {
    return null
  }

  return (
    <Stack
      direction="row"
      sx={{
        mt: 3,
        mb: 40,
        flexWrap: "wrap",
        justifyContent: "flex-start",
        maxWidth: "1000px",
        width: "100%",
      }}
      ref={ref}
    >
      <Accordion
        allowToggle
        sx={{
          display: "flex",
          flexWrap: "wrap",
          ".chakra-collapse": { overflow: "visible !important" },
        }}
      >
        {members.map((member) => (
          <AccordionItem
            key={member.id}
            sx={{ minW: 180, maxW: 180, border: "none" }}
            onClick={() => setSelectedMember(member)}
          >
            <AccordionButton>
              <Box
                sx={{
                  borderBottom:
                    // 変数で色指定
                    selectedMember?.id === member.id ? "3px solid #2196f3" : "3px solid #FFFFFF",
                  paddingBottom: 3,
                }}
              >
                <Avatar
                  src={member.avatar || ""}
                  sx={{
                    width: 120,
                    height: 120,
                  }}
                />
                <Text variant="body1" sx={{ textAlign: "center", width: "100%", mt: 1 }}>
                  {member.name}
                </Text>
                <Text
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    color: "gray.500",
                  }}
                >
                  {member.jobTitle}
                </Text>
              </Box>
            </AccordionButton>
            <AccordionPanel
              className={member.id}
              pb={4}
              sx={{
                transform: `translateX(-${bioTranslateX}px)`,
                width: bioWidth,
                transitionDuration: "500ms",
                py: 10,
              }}
            >
              <Box sx={{ alignItems: "center", display: "flex" }}>
                <Heading size="md">{member.name}</Heading>
                <Text sx={{ color: "gray.500", ml: 2 }}>{member.jobTitle}</Text>
              </Box>

              <Text
                sx={{
                  textAlign: "left",
                  whiteSpace: "pre-wrap",
                  my: 2,
                  wordBreak: "break-word",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  fontSize: "md",
                  pr: "10px",
                }}
              >
                {member.bio}
              </Text>

              <Link href={`/members/${member.id}`} sx={{ display: "flex", alignItems: "center" }}>
                詳細へ
              </Link>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Stack>
  )
}

MemberList.defaultProps = {
  members: undefined,
}

// biome-ignore lint/style/noDefaultExport: ...
export default MemberList
