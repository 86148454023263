import type React from "react"
import { useEffect, useState } from "react"

import {
  Box,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { Auth } from "aws-amplify"
import { FaGithub, FaPlusCircle, FaTwitterSquare } from "react-icons/fa"
import { SocialAccountProvider } from "rikeimatch-graphql"

import { notifySaveFailure, notifySaveSuccess } from "~/components/atoms"
import { oauthAppPath } from "~/config"
import type { JobSeekerMySocialAccountsQuery } from "~/generated/graphql"
import {
  useJobSeekerAssociateSocialAccountMutation,
  useJobSeekerDisassociateSocialAccountMutation,
} from "~/generated/graphql"

import githubTextLogo from "./GitHub-logo.png"
import noteLogoText from "./note-logo-text.svg"
import noteLogo from "./note-logo.png"
import qiitaLogo from "./qiita-logo.png"

import type { ApolloQueryResult } from "@apollo/client"
import type { SocialAccount } from "rikeimatch-graphql"

const providers = [
  SocialAccountProvider.Github,
  SocialAccountProvider.Note,
  SocialAccountProvider.Qiita,
  SocialAccountProvider.Twitter,
]
const sortProviders = (a: SocialAccount, b: SocialAccount) =>
  providers.indexOf(a.provider) - providers.indexOf(b.provider)

const providerIconSize = { base: "50px", sm: "80px" }
const providerIcon = (provider: SocialAccountProvider, type: "logo" | "text" = "logo") => {
  switch (provider) {
    case SocialAccountProvider.Github:
      return type === "text" ? (
        <Box w={providerIconSize}>
          <img src={githubTextLogo} alt="GitHub" />
        </Box>
      ) : (
        <Icon as={FaGithub} boxSize={providerIconSize} title="GitHub" />
      )
    case SocialAccountProvider.Qiita:
      return (
        <Box w={providerIconSize}>
          <img src={qiitaLogo} alt="Qiita" />
        </Box>
      )
    case SocialAccountProvider.Note:
      return (
        <Box w={providerIconSize}>
          <img src={type === "logo" ? noteLogo : noteLogoText} alt="note" />
        </Box>
      )
    case SocialAccountProvider.Twitter:
      return type === "text" ? (
        <Stack direction="row" spacing="10px" alignItems="center" flexWrap="wrap">
          <Icon as={FaTwitterSquare} color="blue.300" boxSize={providerIconSize} />
          <Text>Twitter</Text>
        </Stack>
      ) : (
        <Box objectFit="contain">
          <Icon as={FaTwitterSquare} color="blue.300" boxSize={providerIconSize} />
        </Box>
      )
    default:
      return <span />
  }
}

const ManualInputForm: React.FC<{
  provider: SocialAccountProvider
  afterSubmit: () => Promise<unknown>
  disabled: boolean
}> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { afterSubmit, provider, disabled } = props
  const [socialId, setSocialId] = useState<string | undefined>()
  const [associateAccount, { error }] = useJobSeekerAssociateSocialAccountMutation()

  const handleSubmit = async () => {
    if (!socialId) {
      return
    }
    const result = await associateAccount({
      variables: { input: { provider, socialId } },
    })

    if (error || !result.data?.associateSocialAccount) {
      notifySaveFailure()

      return
    }

    void afterSubmit()
    onClose()
    notifySaveSuccess()
  }

  return (
    <>
      <Button isDisabled={disabled} onClick={onOpen}>
        {disabled ? "連携済み" : "連携する"}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>公開プロフィールのURL</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <InputGroup size="sm">
                <InputLeftAddon>https://note.com/</InputLeftAddon>
                <Input onChange={(event) => setSocialId(event.target.value)} />
              </InputGroup>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              閉じる
            </Button>
            <Button colorScheme="blue" onClick={handleSubmit}>
              送信
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const SocialAccountForm: React.FC<{
  socialAccounts: SocialAccount[]
  refetch: () => Promise<ApolloQueryResult<JobSeekerMySocialAccountsQuery>>
}> = (props) => {
  const { isOpen, onToggle } = useDisclosure()

  const [jwt, setJwt] = useState<string | undefined>()
  useEffect(() => {
    const getJwt = async (): Promise<void> => {
      const session = await Auth.currentSession()
      setJwt(session.getAccessToken().getJwtToken())
    }
    void getJwt()
  })

  const { socialAccounts, refetch } = props
  const sortedSocialAccounts = [...socialAccounts]
  sortedSocialAccounts.sort(sortProviders)

  const [disassociateAccount, { error }] = useJobSeekerDisassociateSocialAccountMutation()
  const handleDelete = async (provider: SocialAccountProvider) => {
    const result = await disassociateAccount({
      variables: { provider },
    })

    if (error || !result.data?.disassociateSocialAccount.success) {
      notifySaveFailure()

      return
    }

    void refetch()
    notifySaveSuccess()
  }

  return (
    <Box>
      <Stack direction="row" spacing={{ base: "15px", sm: "30px" }} alignItems="center">
        {sortedSocialAccounts.length === 0 ? (
          <Text>まだ連携していません。さっそく連携してみましょう！</Text>
        ) : (
          sortedSocialAccounts.map((account) => (
            <Link key={account.provider} href={account.profileUrl} isExternal>
              {providerIcon(account.provider)}
            </Link>
          ))
        )}
        <Button onClick={onToggle}>
          <FaPlusCircle />
        </Button>
      </Stack>
      <Modal {...{ isOpen, onClose: onToggle }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>ソーシャルアカウント連携</ModalHeader>
          <ModalBody borderRadius="5px">
            <ModalCloseButton zIndex={2} />
            <Stack
              direction="column"
              spacing="30px"
              padding="20px"
              divider={<StackDivider borderColor="gray.200" />}
            >
              {jwt &&
                providers.map((current) => {
                  const provider = sortedSocialAccounts.find((p) => p.provider === current)

                  // Twitter連携済みの場合は表示、未連携の場合は非表示にしたいため
                  if (current === SocialAccountProvider.Twitter && !provider) {
                    return null
                  }

                  return (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      key={current}
                    >
                      {providerIcon(current, "text")}
                      {current === SocialAccountProvider.Note ? (
                        <Stack direction="row" spacing="5px">
                          <ManualInputForm
                            provider={current}
                            afterSubmit={refetch}
                            disabled={!!provider}
                          />
                          {provider && (
                            <Button
                              colorScheme="red"
                              type="button"
                              onClick={() => handleDelete(current)}
                            >
                              解除する
                            </Button>
                          )}
                        </Stack>
                      ) : (
                        <form action={`${oauthAppPath}/${current}`} method="get">
                          <input type="hidden" value={jwt} name="jwtToken" />
                          <Stack direction="row" spacing="5px">
                            <Button isDisabled={!!provider || current === "twitter"} type="submit">
                              {provider ? "連携済み" : "連携する"}
                            </Button>
                            {provider && (
                              <Button
                                colorScheme="red"
                                type="button"
                                onClick={() => handleDelete(current)}
                              >
                                解除する
                              </Button>
                            )}
                          </Stack>
                        </form>
                      )}
                    </Stack>
                  )
                })}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default SocialAccountForm
