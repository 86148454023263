import type React from "react"
import { useState } from "react"

import { Box, Tooltip } from "@chakra-ui/react"
import { BsQuestionCircle } from "react-icons/bs"

import type { TooltipProps } from "@chakra-ui/react"

type Props = TooltipProps & {
  children: React.ReactNode
}

const FormTooltip = ({ sx, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Tooltip
      isOpen={isOpen}
      hasArrow
      label={children}
      bg="gray.200"
      sx={{
        ...sx,
        borderRadius: "4px",
        mx: 2,
        p: 4,
        color: "black",
      }}
    >
      <Box
        onClick={() => setIsOpen(!isOpen)}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        sx={{
          fontSize: "1.2rem",
          marginLeft: "8px",
          alignItems: "center",
          display: "flex",
        }}
      >
        <BsQuestionCircle />
      </Box>
    </Tooltip>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default FormTooltip
