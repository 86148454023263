import type React from "react"
import { useState } from "react"

import { Box, Text, Tooltip } from "@chakra-ui/react"

type PropsType = {
  label: string
  children: React.ReactNode
}

const ResponsiveTooltip = ({ label, children }: PropsType) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Tooltip isOpen={isOpen} hasArrow label={<Text sx={{ p: 4 }}>{label}</Text>}>
      <Box
        onClick={() => setIsOpen(!isOpen)}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        sx={{
          fontSize: "1.2rem",
          alignItems: "center",
          display: "flex",
        }}
      >
        {children}
      </Box>
    </Tooltip>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default ResponsiveTooltip
