import type React from "react"

import { Flex, Text } from "@chakra-ui/react"

type Props = {
  title: string
  subtitle: string
}

const SectionHeader: React.FC<Props> = ({ title, subtitle }) => (
  <Flex alignItems="center" direction="column" pb="20px">
    <Text fontSize="2.5rem" letterSpacing="wide">
      {title}
    </Text>
    <Text fontSize="0.8rem" color="#a29a1f" letterSpacing="0.4rem">
      {subtitle}
    </Text>
  </Flex>
)

// biome-ignore lint/style/noDefaultExport: ...
export default SectionHeader
