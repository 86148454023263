import type { ReactNode } from "react"

import { FormLabel as ChakraFormLabel, useColorModeValue } from "@chakra-ui/react"

import type { FormLabelProps } from "@chakra-ui/react"

type Props = {
  children: ReactNode
} & FormLabelProps

const FormLabel = ({ children, ...props }: Props): ReactNode => {
  const formLabelColor = useColorModeValue("gray.700", "gray.50")

  return (
    <ChakraFormLabel
      fontSize="sm"
      fontWeight="md"
      marginBottom={1}
      color={formLabelColor}
      {...props}
    >
      {children}
    </ChakraFormLabel>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default FormLabel
