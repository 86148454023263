import { useJobSeekerUnreadItemsCountQuery } from "~/generated/graphql"

const useUnreadItemsCount = () => {
  const { data, loading, refetch } = useJobSeekerUnreadItemsCountQuery()
  const unreadItemsCount = data?.unreadItemsCount

  const unreadJobLikesCount = unreadItemsCount?.jobLikes || 0
  const unreadScoutsCount = unreadItemsCount?.sentScouts || 0
  const unreadMessagesCount = unreadItemsCount?.messages || 0

  const hasUnreadJobLikes = unreadJobLikesCount > 0
  const hasUnreadScouts = unreadScoutsCount > 0
  const hasUnreadMessages = unreadMessagesCount > 0

  return {
    unreadJobLikesCount,
    unreadScoutsCount,
    unreadMessagesCount,
    hasUnreadJobLikes,
    hasUnreadScouts,
    hasUnreadMessages,
    loading,
    refetch,
  }
}

// biome-ignore lint/style/noDefaultExport: ...
export default useUnreadItemsCount
