import type { ReactNode } from "react"
import React, { useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"

import {
  Box,
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"

import HeaderImage from "~/components/templates/images/20240610_introduce_campaign_header.jpg"
import AIEntryImage from "~/components/templates/images/20240820_ai_entry.png"
import { useJobSeekerCreateUserCampaignViewMutation } from "~/generated/graphql"

import type { Campaign } from "rikeimatch-graphql"

type PropsType = {
  campaigns: Campaign[]
}

type ModalWrapperProps = {
  isOpen: boolean
  handleOnClose: () => void
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "full"
  children: ReactNode
}

const ModalWrapper = ({ isOpen, handleOnClose, size, children }: ModalWrapperProps) => (
  <Modal isOpen={isOpen} onClose={handleOnClose} size={size || "md"}>
    <ModalOverlay />
    {children}
  </Modal>
)

const CampaignModal = ({ campaigns }: PropsType) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [viewedCampaignIds, setViewedCampaignIds] = React.useState<string[]>([])
  const campaign = campaigns.filter((campaign) => !viewedCampaignIds.includes(campaign.id))[0]

  const [createUserCampaignView] = useJobSeekerCreateUserCampaignViewMutation()

  const handleOnClose = async () => {
    onClose()
    await createUserCampaignView({
      variables: {
        input: {
          campaignId: campaign.id,
        },
      },
      onCompleted: () => {
        setViewedCampaignIds([...viewedCampaignIds, campaign.id])
      },
    })
  }

  useEffect(() => {
    if (campaign) {
      onOpen()
    }
  }, [campaign, onOpen])

  if (!campaign) {
    return null
  }

  if (campaign.code === "20240820_ai_entry") {
    return (
      <ModalWrapper isOpen={isOpen} handleOnClose={handleOnClose} size="xl">
        <ModalContent>
          <ModalBody>
            <img
              src={AIEntryImage}
              style={{ maxWidth: "100%", margin: "auto" }}
              alt="AI面接応募のロゴ"
            />
            <Stack spacing={3} sx={{ my: 4 }}>
              <Text variant="body1">
                募集特集に「AI面接」の新機能を搭載！
                <br />
                気になる企業に対して、AIによる模擬面接ができるようになりました。
              </Text>
              <Text variant="body1">
                ベストアンサーの場合、提出・応募ができます。
                <br />
                ※上手くいかなかった場合は提出せずフィードバックだけ貰えます
              </Text>
              <Text>内容次第では一部選考免除など、特別な案内が届く場合もあります！</Text>
              <Text variant="body1">
                詳細については、下記の記事にしましたのでご参照ください。
                <br />
                <Link
                  href="https://useful-radio-75c.notion.site/AI-0b62765b8a214c2cad379bb052763d10?pvs=25"
                  isExternal
                >
                  https://useful-radio-75c.notion.site/AI-0b62765b8a214c2cad379bb052763d10?pvs=25
                </Link>
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack direction="row" spacing={2}>
              <Button variant="ghost" onClick={handleOnClose}>
                閉じる
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </ModalWrapper>
    )
  }

  if (campaign.code === "202402_ai_interview") {
    return (
      <ModalWrapper isOpen={isOpen} handleOnClose={handleOnClose}>
        <ModalContent>
          <ModalHeader>AI面接診断(β)のリリース</ModalHeader>
          <ModalBody>
            <Text variant="body1">
              AI面接診断(β)がリリースされました！
              <br />
              <br />
              登録のプロフィール内容をもとにAI面接官から質問が届きます。その質問に答えていき、終了後にはフィードバックも貰える仕組みです。ぜひお試しください。
              <br />
              <br />※ 本機能はβ版であり、正式版に向けてアップデートを進めていきます。
              <br />
              <br />
              <br />
            </Text>
          </ModalBody>
          <ModalFooter>
            <Stack direction="row" spacing={2}>
              <Button variant="ghost" onClick={handleOnClose}>
                閉じる
              </Button>
              <Link as={RouterLink} to="/interview" textDecoration="none">
                <Button colorScheme="blue" onClick={handleOnClose}>
                  詳しく見る
                </Button>
              </Link>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </ModalWrapper>
    )
  }

  if (campaign.code === "202403_grade_promotion") {
    return (
      <ModalWrapper isOpen={isOpen} handleOnClose={handleOnClose} size="xl">
        <ModalContent>
          <ModalHeader>2024年3月31日 学年の自動切り替えについて</ModalHeader>
          <ModalBody>
            <Text variant="body1">
              <b>2024年3月31日 22:00～24:00</b>
              の間に、運営側で皆様の学年の自動切り替えを実施します。
              <br />
              <br />
              注意点になりますが、全員ではなく一部の方々が対象となります。
              <br />
              下記は学年ごとの方針になりますためご参照ください。
              <br />
              <br />
              【学年ごとの実施方針】
              <br />
              <b>①学部１～３年、修士１年、高専１～４年（手動での切り替えは不要です！）</b>
              <br />
              上記学年の方々は、前述した時刻になりましたら自動的に学年が切り替わります。
              <br />
              例：学部2年の方は学部3年へ。修士1年の方は修士2年へ。
              <br />
              <br />
              <b>
                ②学部４年、修士２年、高専５年、博士全学年、専門全学年（手動での切り替えが必要です！）
              </b>
              <br />
              上記学年の方々は、自動的に学年が切り替わらず今のままの設定になります。
              <br />
              学部4年で院に進学される場合は、お手数ですが学年を手動でご変更ください。
              <br />
              また、手動切り替えは必ず4月1日以降にお願いします（自動切り替えの終了後）
              <br />
              <br />
              【卒業年度について】
              <br />
              卒業年度は弊社側で変更は行いません。
              <br />
              留年・留学・院進などで卒業年度に変化が生じた場合、お手数ですが手動で切り替えをお願いします。
              <br />
              ※企業は、卒業年度もチェックしてスカウトを行う傾向が強いです。
              <br />
              卒業年度で誤りがある場合、適切なスカウトを受信できない可能性が高いため、併せてご確認ください。
              <br />
              <br />
              学年切り替えについてご不明点がございましたら、下記メール宛へお気軽にご連絡いただけますと幸いです。
              <br />
              contact@cambiainc.co.jp
              <br />
              <br />
              リケイマッチはリリースから2年半が経過いたしましたが、
              <br />
              今後も皆さまの就職活動のお力になれるよう、運営一同励んでまいります。
              <br />
              <br />
              ご確認の程、何卒宜しくお願いいたします。
              <br />
            </Text>
          </ModalBody>
          <ModalFooter>
            <Stack direction="row" spacing={2}>
              <Button variant="ghost" onClick={handleOnClose}>
                閉じる
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </ModalWrapper>
    )
  }

  if (campaign.code === "20240610_introduce_campaign") {
    return (
      <ModalWrapper isOpen={isOpen} handleOnClose={handleOnClose} size="xl">
        <ModalContent>
          <ModalHeader>【7月末まで】26卒を対象に友人紹介キャンペーンを開始しました！</ModalHeader>
          <ModalBody>
            <img
              src={HeaderImage}
              style={{ maxWidth: "100%", margin: "auto" }}
              alt="友人紹介キャンペーンのロゴ"
            />
            <Stack spacing={3} sx={{ my: 4 }}>
              <Text>【7月末まで】</Text>

              <Text variant="body1">26卒を対象に友人紹介キャンペーンを開始しました！</Text>
              <Text variant="body1">
                1人の紹介につき2,000円のAmazonギフトを紹介者と友人にプレゼント。
                友人だけでなく先輩・後輩が該当する場合もぜひご紹介ください！
              </Text>
              <Box>
                <Text variant="body1">詳細はこちらから</Text>
                <Link href="/campaign" onClick={handleOnClose}>
                  https://rikeimatch.com/campaign
                </Link>
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack direction="row" spacing={2}>
              <Button variant="ghost" onClick={handleOnClose}>
                閉じる
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </ModalWrapper>
    )
  }

  return null
}

// biome-ignore lint/style/noDefaultExport: ...
export default CampaignModal
