import { Skeleton, Stack } from "@chakra-ui/react"

const LoadingSkeleton = () => (
  <Stack spacing={10} sx={{ m: 10 }}>
    <Skeleton height="50px" />
    <Skeleton height="50px" />
    <Skeleton height="50px" />
    <Skeleton height="50px" />
  </Stack>
)

// biome-ignore lint/style/noDefaultExport: ...
export default LoadingSkeleton
