import { InfoOutlineIcon } from "@chakra-ui/icons"
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react"
import { formatISODatetime } from "common-utils"
import { ChatGptMessageRole, ChatGptThreadKind } from "rikeimatch-graphql"

import { notifySaveFailure, notifySaveSuccess } from "~/components/atoms"
import { UserAvatar } from "~/components/molecules"
import { useUser } from "~/containers"
import {
  useJobSeekerAiInterviewHistoriesQuery,
  useJobSeekerAiInterviewHistoryQuery,
  useJobSeekerChatGptMessagesQuery,
  useJobSeekerCreateAiInterviewHistoryMutation,
  useJobSeekerDeleteAiInterviewHistoryMutation,
} from "~/generated/graphql"
import type { JobSeekerChatGptThreadsQuery } from "~/generated/graphql"

import LoadingSkeleton from "../molecules/LoadingSkeleton"
import ResponsiveTooltip from "../molecules/ResponsiveTooltip"

type PropsType = {
  thread: JobSeekerChatGptThreadsQuery["chatGptThreads"][0]
  isPublished?: boolean
}

const InterviewHistoryThread = ({ thread, isPublished }: PropsType) => {
  const { user, image } = useUser()
  const { data, loading } = useJobSeekerChatGptMessagesQuery({
    variables: {
      threadId: thread.id,
    },
  })
  const { data: histroiesData, refetch } = useJobSeekerAiInterviewHistoriesQuery()
  const aiInterviewHistories = histroiesData?.aiInterviewHistories
  const { data: historyData, refetch: refetchHistory } = useJobSeekerAiInterviewHistoryQuery({
    variables: {
      chatGptThreadId: thread.id,
    },
  })
  const aiInterviewHistory = historyData?.aiInterviewHistory

  const [createAiInterviewHistory] = useJobSeekerCreateAiInterviewHistoryMutation()
  const [deleteAiInterviewHistory] = useJobSeekerDeleteAiInterviewHistoryMutation()

  const handleCreateAiInterviewHistory = async () => {
    await createAiInterviewHistory({
      variables: {
        input: {
          chatGptThreadId: thread.id,
        },
      },
      onCompleted: () => {
        notifySaveSuccess()
        void refetch()
        void refetchHistory()
      },
    })
  }

  const handleDeleteAiInterviewHistory = async () => {
    await deleteAiInterviewHistory({
      variables: {
        input: {
          id: aiInterviewHistory?.id || "",
        },
      },
      onCompleted: () => {
        notifySaveSuccess()
        void refetch()
      },
      onError: () => {
        notifySaveFailure()
      },
    })
  }

  const messages = data?.chatGptMessages

  if (loading) {
    return <LoadingSkeleton />
  }
  if (!messages) {
    return <div>No messages</div>
  }

  const messagesWithoutSystem = messages.filter(
    (message) => message.role !== ChatGptMessageRole.System,
  )

  return (
    <Stack sx={{ h: "100%", w: "100%", overflowY: "hidden" }}>
      <Card sx={{ boxShadow: "none", h: "100%" }}>
        <CardHeader
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { base: "column", md: "row" },
            py: { base: 2, md: 4 },
          }}
        >
          <Heading size="md" sx={{ py: 2 }}>
            {formatISODatetime(thread.createdAt)} の面接練習
          </Heading>
          <Stack sx={{ alignItems: "center", flexDirection: { base: "column", md: "row" } }}>
            {isPublished && (
              <Alert
                status="warning"
                sx={{ p: { base: 3, md: 4 }, fontSize: { base: 13, md: 16 } }}
              >
                <AlertIcon />
                この面接履歴は企業に公開されています。
              </Alert>
            )}
            <Stack
              direction="row"
              sx={{ h: "100%", w: { base: "100%", md: "auto" }, alignItems: "center" }}
            >
              {isPublished && (
                <Button
                  sx={{ minW: 160, h: "100%", w: { base: "100%", md: "auto" } }}
                  onClick={handleDeleteAiInterviewHistory}
                >
                  非公開にする
                </Button>
              )}
              {thread.kind !== ChatGptThreadKind.JobInterview && (
                <>
                  {!isPublished && (
                    <Button
                      onClick={handleCreateAiInterviewHistory}
                      isDisabled={aiInterviewHistories && aiInterviewHistories.length >= 3}
                    >
                      プロフィールに掲載する
                    </Button>
                  )}
                  <ResponsiveTooltip label="プロフィールに掲載することで、企業担当がスカウト送信の際に「質問に対するあなたの回答」を閲覧できるようになります。自身の個性や魅力を更に伝え、スカウト受信率が増加します。">
                    <InfoOutlineIcon w={6} h={6} />
                  </ResponsiveTooltip>
                </>
              )}
            </Stack>
          </Stack>
        </CardHeader>
        <CardBody sx={{ overflowY: "auto", flexGrow: 1, pb: 24 }}>
          <Stack divider={<StackDivider />} spacing="4">
            {messagesWithoutSystem.map((message) => (
              <Box key={message.id}>
                {message.role === ChatGptMessageRole.User && (
                  <Heading size="sm" sx={{ display: "flex", alignItems: "center" }}>
                    <UserAvatar
                      name={user?.attributes?.family_name}
                      imageKey={image}
                      size="md"
                      sx={{ height: "30px", width: "30px", mr: 2 }}
                    />
                    {user?.attributes?.family_name} さん
                  </Heading>
                )}
                {message.role === ChatGptMessageRole.Assistant && (
                  <Heading size="sm" sx={{ display: "flex", alignItems: "center" }}>
                    <UserAvatar
                      name="ai"
                      size="md"
                      sx={{ height: "30px", width: "30px", mr: 2 }}
                      imageKey=""
                    />
                    AI面接官
                  </Heading>
                )}
                <Text pt="2" fontSize="sm" sx={{ whiteSpace: "pre-wrap" }}>
                  {message?.content}
                </Text>
              </Box>
            ))}
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default InterviewHistoryThread
