import type React from "react"
import { useMemo } from "react"

import {
  Box,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import { MdCheckCircle } from "react-icons/md"
import { SocialAccountProvider } from "rikeimatch-graphql"

import { FormContainer } from "~/components/molecules"
import { HeaderContainer } from "~/components/organisms"
import { oauthAppPath } from "~/config"
import type { JobSeekerMySocialAccountsQuery } from "~/generated/graphql"
import {
  useJobSeekerDisassociateSocialAccountMutation,
  useJobSeekerMySocialAccountsQuery,
} from "~/generated/graphql"
import useCognitoToken from "~/hooks/useCognitoToken"
import useMedia from "~/hooks/useMedia"

import { notifySaveFailure, notifySaveSuccess } from "../atoms"
import LineOrUnlinkButton from "../molecules/LineOrUnlinkButton"

import type { ApolloQueryResult } from "@apollo/client"
import type { JobSeeker } from "rikeimatch-graphql"

const lineProvider = SocialAccountProvider.Line

enum Step {
  Yet = 1,
  Linked = 2,
  Following = 3,
}

const TodoListIcon = ({ done, number }: { done: boolean; number: number }) =>
  done ? (
    <ListIcon as={MdCheckCircle} color="green.500" margin={0} />
  ) : (
    <Text as="span">{number}. </Text>
  )

const HelpText = (props: Pick<JobSeeker, "hasLinkedLineAccount" | "isFollowingLineAccount">) => {
  const { hasLinkedLineAccount, isFollowingLineAccount } = props
  const step = useMemo(() => {
    if (!hasLinkedLineAccount) {
      return Step.Yet
    }
    if (!isFollowingLineAccount) {
      return Step.Linked
    }

    return Step.Following
  }, [hasLinkedLineAccount, isFollowingLineAccount])

  return (
    <List>
      <ListItem>
        <Text fontSize={{ base: "md", md: "lg" }}>
          <TodoListIcon done={step > Step.Yet} number={Step.Yet} />
          「連携する」を押してリケイマッチとLINEを連携させる
        </Text>
      </ListItem>
      <ListItem>
        <Text fontSize={{ base: "md", md: "lg" }}>
          <TodoListIcon done={step > Step.Linked} number={Step.Linked} />
          「友だち追加」を押して友だち登録することで完了！
          <Text fontSize={{ base: "sm", md: "md" }} color="gray.500">
            ※友だち追加後は、画面をリロードしてください
          </Text>
        </Text>
      </ListItem>
    </List>
  )
}

const LineAccountForm: React.FC<{
  hasLinkedLineAccount: boolean | undefined
  isFollowingLineAccount: boolean | undefined
  refetch: () => Promise<ApolloQueryResult<JobSeekerMySocialAccountsQuery>>
}> = (props) => {
  const { jwt } = useCognitoToken()

  const { isMobile } = useMedia()
  const { hasLinkedLineAccount, isFollowingLineAccount, refetch } = props
  const [disassociateAccount, { error }] = useJobSeekerDisassociateSocialAccountMutation()
  const handleDelete = async (provider: SocialAccountProvider) => {
    const result = await disassociateAccount({
      variables: { provider },
    })

    if (error || !result.data?.disassociateSocialAccount.success) {
      notifySaveFailure()

      return
    }

    void refetch()
    notifySaveSuccess()
  }

  // TODO: ブラウザアプリを開いたときにリロードする（できていない）
  // const pageVisibilityStatus = usePageVisibility();
  // if (pageVisibilityStatus) {
  //   // ページが非表示になったらリロードする
  //   void refetch();
  // }
  const isFollowing = !!(hasLinkedLineAccount && isFollowingLineAccount)

  const handleClick = async () => {
    if (isFollowing) {
      await handleDelete(lineProvider)
    } else if (hasLinkedLineAccount && !isFollowingLineAccount) {
      // リンクを踏むので不要
      void undefined
    } else {
      // フォームを送信するので不要
      void undefined
    }
  }

  return (
    <Box>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing="30px"
        padding="20px"
        divider={<StackDivider borderColor="gray.200" />}
      >
        {jwt && (
          <form action={`${oauthAppPath}/${lineProvider}`} method="get">
            <input type="hidden" value={jwt} name="jwtToken" />
            <LineOrUnlinkButton
              hasLinked={hasLinkedLineAccount}
              isFollowing={isFollowing}
              type={hasLinkedLineAccount ? "button" : "submit"}
              onClick={handleClick}
            />
          </form>
        )}
        <HelpText {...{ hasLinkedLineAccount, isFollowingLineAccount }} />
      </Stack>
    </Box>
  )
}

const Settings: React.FC = () => {
  const { data, refetch } = useJobSeekerMySocialAccountsQuery()
  const modalBg = useColorModeValue("white", "gray.700")

  return (
    <Box bg={useColorModeValue("gray.50", "inherit")} p={{ base: 4, md: 10 }}>
      <Box>
        <SimpleGrid
          display={{ base: "initial", md: "grid" }}
          columns={{ md: 4 }}
          spacing={{ md: 6 }}
        >
          {data?.jobSeekerMe && (
            <>
              <HeaderContainer
                header="LINEアカウント連携"
                description="企業から届くスカウト、興味あり、メッセージの通知をLINEで受け取ることができます。"
              />
              <FormContainer>
                <Box shadow="base" rounded={[null, "md"]} overflow={{ sm: "hidden" }}>
                  <Stack px={4} py={5} bg={modalBg} spacing={6} p={{ sm: 8 }}>
                    <LineAccountForm
                      hasLinkedLineAccount={!!data?.jobSeekerMe.hasLinkedLineAccount}
                      isFollowingLineAccount={!!data?.jobSeekerMe.isFollowingLineAccount}
                      refetch={refetch}
                    />
                  </Stack>
                </Box>
              </FormContainer>
            </>
          )}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default Settings
