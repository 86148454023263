import type React from "react"
import { useState } from "react"
import { useForm } from "react-hook-form"
import type { SubmitHandler } from "react-hook-form"
import toast from "react-hot-toast"

import { Box, Button, Flex, Input, Text } from "@chakra-ui/react"
import { Auth } from "aws-amplify"
import { AuthState } from "common-utils"

import { InputLabel } from "~/components/atoms"
import SectionHeader from "~/components/molecules/SectionHeader"
import { useUser } from "~/containers"

import styles from "./AuthForm.module.css"

type EmailInputFormInput = {
  email: string
}

type EmailInputFormProps = {
  showSignInForm: () => void
  showResetPasswordForm: () => void
  setEmail: (email: string) => void
}

const EmailInputForm: React.FC<EmailInputFormProps> = ({
  showSignInForm,
  showResetPasswordForm,
  setEmail,
}) => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<EmailInputFormInput>({
    mode: "onBlur",
  })

  const onSubmit: SubmitHandler<EmailInputFormInput> = async (input) => {
    try {
      await Auth.forgotPassword(input.email)
      setEmail(input.email)
      showResetPasswordForm()
    } catch {
      toast.error("送信に失敗しました")
    }
  }

  return (
    <Box maxW="500px" margin="auto">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box py="15px">
          <InputLabel text="メールアドレス" />
          <Input
            className={styles.txt}
            type="email"
            required
            isInvalid={!!errors.email}
            {...register("email", { required: true })}
          />
          <Text color="#b8526b">{errors.email?.type !== "required" && errors.email?.message}</Text>
        </Box>
        <Flex alignItems="center" direction="column" py="30px">
          <Button
            type="submit"
            isLoading={isSubmitting}
            w="240px"
            h="auto"
            bg="#0a94cf"
            color="white"
            fontWeight="normal"
            fontSize="1.5rem"
            borderRadius="0"
            padding="0.6em 0"
            _hover={{ bg: "#cf1359" }}
          >
            コードを受け取る
          </Button>
          <Box pt="25px">
            <Button variant="link" textColor="#0a94cf" onClick={showSignInForm}>
              戻る
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  )
}

type ResetPasswordFormInput = {
  code: string
  password: string
}

type ResetPasswordFormProps = {
  showSignInForm: () => void
  email: string
  afterComplete: () => void
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  showSignInForm,
  email,
  afterComplete,
}) => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<ResetPasswordFormInput>({
    mode: "onBlur",
  })
  const { login } = useUser()

  const onSubmit: SubmitHandler<ResetPasswordFormInput> = async (input) => {
    try {
      await Auth.forgotPasswordSubmit(email, input.code, input.password)
      await login(email, input.password)
      afterComplete()
    } catch {
      toast.error("設定に失敗しました")
    }
  }

  return (
    <Box maxW="500px" margin="auto">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box py="15px">
          <InputLabel text="コード" />
          <Input
            className={styles.txt}
            type="number"
            required
            isInvalid={!!errors.code}
            {...register("code", { required: true })}
          />
          <Text color="#b8526b">{errors.code?.type !== "required" && errors.code?.message}</Text>
        </Box>
        <Box py="15px">
          <InputLabel text="新しいパスワード" />
          <Input
            className={styles.txt}
            type="password"
            autoComplete="new-password"
            required
            isInvalid={!!errors.password}
            {...register("password", { required: true })}
          />
          <Text color="#b8526b">
            {errors.password?.type !== "required" && errors.password?.message}
          </Text>
        </Box>
        <Flex alignItems="center" direction="column" py="30px">
          <Button
            type="submit"
            isLoading={isSubmitting}
            w="240px"
            h="auto"
            bg="#0a94cf"
            color="white"
            fontWeight="normal"
            fontSize="1.5rem"
            borderRadius="0"
            padding="0.6em 0"
            _hover={{ bg: "#cf1359" }}
          >
            設定する
          </Button>
          <Box pt="25px">
            <Button variant="link" textColor="#0a94cf" onClick={showSignInForm}>
              戻る
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  )
}

export type Props = {
  showSignInForm: () => void
  afterComplete: () => void
}

const ForgotPasswordForm: React.FC<Props> = ({ showSignInForm, afterComplete }) => {
  const [mode, setMode] = useState<AuthState.ForgotPassword | AuthState.ResetPassword>(
    AuthState.ForgotPassword,
  )
  const [email, setEmail] = useState<string>("")

  return (
    <>
      <SectionHeader title="パスワードリセット" subtitle="forgot password" />
      {mode === AuthState.ForgotPassword ? (
        <EmailInputForm
          showSignInForm={showSignInForm}
          showResetPasswordForm={() => setMode(AuthState.ResetPassword)}
          setEmail={(newEmail) => setEmail(newEmail)}
        />
      ) : (
        <ResetPasswordForm
          showSignInForm={showSignInForm}
          email={email}
          afterComplete={afterComplete}
        />
      )}
    </>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default ForgotPasswordForm
