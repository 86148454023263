import type { ReactNode } from "react"

import { Box, Flex, GridItem, Heading, Text, useColorModeValue } from "@chakra-ui/react"

type Props = {
  header: string
  description?: string
  tooltip?: ReactNode
}

const HeaderContainer = ({ header, description, tooltip }: Props): ReactNode => {
  const colorModeValue = useColorModeValue("gray.600", "gray.400")

  return (
    <GridItem colSpan={{ md: 1 }}>
      <Box px={{ base: 0, md: 4 }}>
        <Flex sx={{ alignItems: "center" }}>
          <Heading fontSize="lg" fontWeight="md" lineHeight="6" as="h2">
            {header}
          </Heading>
          {tooltip}
        </Flex>
        {description && (
          <Text
            color={colorModeValue}
            as="h3"
            sx={{
              wordBreak: "break-all",
              whiteSpace: "pre-wrap",
              mt: 1,
              fontSize: "sm",
            }}
          >
            {description}
          </Text>
        )}
      </Box>
    </GridItem>
  )
}

HeaderContainer.defaultProps = {
  description: undefined,
  tooltip: undefined,
}

// biome-ignore lint/style/noDefaultExport: ...
export default HeaderContainer
