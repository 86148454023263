import { useEffect, useRef, useState } from "react"

import { Avatar, Button, Stack, Text } from "@chakra-ui/react"
import { Storage } from "aws-amplify"
import { v4 as uuidV4 } from "uuid"

import { getPublicObject } from "../atoms"

type Props = {
  handleAfterUpload: (uploadPath: string) => void
  helperText?: string
}

const AvatarUploader = ({ handleAfterUpload, helperText }: Props) => {
  const [image, setImage] = useState<string | undefined>()
  const [uploadedImage, setUploadedImage] = useState<string | undefined>(undefined)

  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (!hiddenFileInput?.current) {
      return
    }
    hiddenFileInput.current.click()
  }

  const onChange = async (file: File | null) => {
    if (!file) {
      return
    }

    const resourceName = file?.name || "image"
    const uuid = uuidV4()

    const result = await Storage.put(`referral_comments/avatar/${uuid}/${resourceName}`, file, {
      level: "public",
    })
    setImage(result.key)
  }

  useEffect(() => {
    if (!image) {
      return
    }

    void getPublicObject(image).then((result) => {
      if (!result) {
        return
      }

      setUploadedImage(result)
    })
  }, [image])

  useEffect(() => {
    if (!uploadedImage) {
      return
    }

    handleAfterUpload(uploadedImage)
  }, [uploadedImage, handleAfterUpload])

  return (
    <Stack direction="row" gap={4}>
      <Avatar src={uploadedImage} size="xl" name="hoe" />
      <Stack sx={{ textAlign: "left", justifyContent: "flex-end" }}>
        <Text sx={{ color: "grey", fontSize: "xs" }}>{helperText}</Text>
        <Button
          onClick={handleClick}
          variant="outline"
          size="sm"
          fontWeight="medium"
          _focus={{ shadow: "none" }}
        >
          画像を選択
        </Button>
        <input
          type="file"
          accept="image/*"
          ref={hiddenFileInput}
          onChange={(e) => onChange(e.target.files?.[0] || null)}
          hidden
        />
      </Stack>
    </Stack>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default AvatarUploader
