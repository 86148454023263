import type React from "react"
import type { ReactNode } from "react"

import { ChakraProvider, extendTheme } from "@chakra-ui/react"

import type { ComponentStyleConfig } from "@chakra-ui/theme"

// デフォルトと同じ設定は変更不可（拡張は可能）
const breakpoints = {
  xs: "23em",
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
}

// 禁則処理 https://wemo.tech/1909
const japaneseWordBreaks = {
  styles: {
    global: {
      "h1,h2,h3": {
        wordBreak: "keep-all",
        lineBreak: "strict",
        wordWrap: "break-word",
        overflowWrap: "break-word",
      },
    },
  },
}

const Textarea: ComponentStyleConfig = {
  variants: {
    rikeimatch: {
      borderColor: "gray.300",
      borderWidth: "1px",
      "&:hover": {
        borderColor: "gray.700",
      },
      '&[aria-invalid="true"]': {
        borderColor: "red.500",
      },
    },
  },
  defaultProps: {
    variant: "rikeimatch",
  },
}

const Input: ComponentStyleConfig = {
  variants: {
    rikeimatch: {
      field: {
        borderColor: "gray.300",
        borderWidth: "1px",
        "&:hover": {
          borderColor: "gray.700",
        },
        '&[aria-invalid="true"]': {
          borderColor: "red.500",
        },
      },
    },
  },
  defaultProps: {
    variant: "rikeimatch",
  },
}

const theme = extendTheme({
  breakpoints,
  components: { Input, Textarea },
  ...japaneseWordBreaks,
})

type Props = {
  children: ReactNode
}

const UiProvider: React.FC<Props> = ({ children }) => (
  <ChakraProvider theme={theme}>{children}</ChakraProvider>
)

// biome-ignore lint/style/noDefaultExport: ...
export default UiProvider
