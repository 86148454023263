import type React from "react"

import { Box, SimpleGrid, useColorModeValue } from "@chakra-ui/react"

import { FormContainer } from "~/components/molecules"
import { HeaderContainer } from "~/components/organisms"
import { useJobSeekerMailSettingsQuery } from "~/generated/graphql"

import { MailSettingsForm } from "../organisms/MailSettingsForm"

const AccountSetting: React.FC = () => {
  const { data } = useJobSeekerMailSettingsQuery()

  return (
    <Box bg={useColorModeValue("gray.50", "inherit")} p={{ base: 4, md: 10 }}>
      <Box>
        <SimpleGrid
          display={{ base: "initial", md: "grid" }}
          columns={{ md: 4 }}
          spacing={{ md: 6 }}
        >
          <>
            <HeaderContainer header="アカウント設定" />
            <FormContainer>
              <Box shadow="base" rounded={[null, "md"]} overflow={{ sm: "hidden" }}>
                {data?.jobSeekerMailSettings && (
                  <MailSettingsForm jobSeekerMailSettings={data.jobSeekerMailSettings} />
                )}
              </Box>
            </FormContainer>
          </>
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export { AccountSetting }
