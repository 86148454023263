import type React from "react"
import { useState } from "react"

import { FormLabel, Switch } from "@chakra-ui/react"

const MailSettingToggleButton: React.FC<{
  mailSettingType: string
  displayedTitle: string
  disallowed: boolean
  handleToggle: () => void
}> = ({ mailSettingType: type, displayedTitle, disallowed, handleToggle }) => {
  const [isDisallowed, setIsDisallowed] = useState(disallowed)

  const handleChange = () => {
    setIsDisallowed(!isDisallowed)
    handleToggle()
  }

  return (
    <>
      <FormLabel htmlFor={type} mb="0">
        {displayedTitle}
      </FormLabel>
      {/* トグルオン(通知あり)：disallowed=false */}
      {/* トグルオフ(通知なし)：disallowed=true */}
      <Switch id={type} isChecked={!isDisallowed} onChange={handleChange} />
    </>
  )
}

export { MailSettingToggleButton }
